import React, { useState, useEffect } from 'react'
import {Container} from './style'

import { Button, Input, Modal, Table, Popconfirm, Col, Form, message, Icon, Upload, Select } from 'antd'

import moment from 'moment'

import StoreSettingHttp from '../../../request/gate'

import CommonRequest from '../../../request/common-request'

import DeTable from '../../../component/DeTable';

const _request = new StoreSettingHttp()
const _common = new CommonRequest()

function Gate(props) {
    const [modalType, setModalType] = useState('') //新增or编辑
    const [editVisible, setEditVisible] = useState(false) //编辑项
    const [confirmLoading, setConfirmLoading] = useState(false) //编辑模态框加载图标
	const [imageUrl, setImageUrl] = useState('')
    const [name, setName] = useState('')
	const [state, setState] = useState('')
	const [washList, setWashList] = useState([])
	const [placeList, setPlaceList] = useState([])
	const [washName, setWashName] = useState([])
	const [equipmentNo,setEquipmentNo] = useState('')
    const tableColunm = 
        [
            {
                title: '序号',
                key: 'number',
                render: (text, record, index) => (
                    <span>{index + 1}</span>
                )
            },
            {
                title: '洗消点',
                dataIndex: 'washSpotName',
                key: 'washSpotName',
            },
            {
                title: '闸机位置',
                key: 'equipmentPosition',
                dataIndex: 'equipmentPosition',
                 render: (text, record, index) => (
                	placeList.map((item,index1) => {
						if(record.equipmentPosition === item.dictValue) {
							return  <span>{item.dictDesc}</span>
						}
					})
                   
                )
            },
			{
			    title: '设备号',
			    key: 'equipmentNo',
			    dataIndex: 'equipmentNo',
			},
			{
			    title: '操作人',
			    key: 'createUser',
			    dataIndex: 'createUser',
			},
			{
			    title: '操作时间',
			    key: 'createDate',
			    dataIndex: 'createDate',
				render: (text, record, index) => (
				    <span>{moment(text).format("YYYY-MM-DD HH:mm:ss")}</span>
				)
			},
            {
                title: '操作',
                key: 'action',
                render: (text, record, index) => (
                    <span>
						<Popconfirm title="确认删除？" okText="删除" okType="danger" cancelText="取消" onConfirm={deleteRow.bind(this, record.id)}>
							<Button style={{ marginRight: 8 }} type="danger" size="small">删除</Button>
						</Popconfirm>
                        <Button style={{ marginRight: 8 }} type="primary" size="small" onClick={openModal.bind(this, 'edit', record)}>编辑</Button>
						<Button style={{ marginRight: 8 }} type="primary" size="small" onClick={openGate.bind(this, record)}>开闸</Button>
						<Button style={{ marginRight: 8 }} type="primary" size="small" onClick={openModal.bind(this, 'record', record,record.id,1,10)}>记录</Button>
                    </span>
                ),
            },
        ]
		
	// 开闸	
    const openGate = record => {
		message.info(record.equipmentNo);
	}
		
	const gateRecordColunm =
	    [
	        {
	            title: '序号',
	            key: 'number',
	            render: (text, record, index) => (
	                <span>{index + 1}</span>
	            )
	        },
			{
			    title: '开闸时间',
			    key: 'openDate',
			    dataIndex: 'openDate',
			},
			{
			    title: '车牌号',
			    key: 'carNo',
			    dataIndex: 'carNo',
			},
	    ]	
		
     //表格结构
    const [tableTotal, setTableTotal] = useState(0)
    const [tableData, setTableData] = useState([]) //表格数据
	
	// 闸机记录表格结构
	const [gateRecordDate, setGateRecordDate] = useState([]) //表格数据
    const [detailGateId, setDetailGateId] = useState('')
    const [tableDetailTotal, setTableDetailTotal] = useState(0)
	
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    }
    const { getFieldDecorator } = props.form
    useEffect(() => {
        getGateList()
        manyPlaceList()
    }, [])
    //获取闸机列表
    const getGateList = (ButtonType, pageNo = 1, pageSize = 10) => {
        let params = {
            equipmentNo,
			washName,
            pageNo,
            pageSize
        }
        if (ButtonType === 'reset') {
            params = {}
        }
        _request.getGateList(params).then(res => {
            setTableTotal(res.total || 0)
            setTableData(res.list)
        })
    }
    //删除最外的表格行
    const deleteRow = (id) => {
        let params = {
            id
        }
        _request.deleteGateInfo(params).then(res => {
            message.info('删除成功！')
            getGateList()
        })
    }
	const washSpotList = () => {
		let params = {}
		_common.getWashSpotList(params).then(res => {
			setWashList(res)
		})
	}
	
	const manyPlaceList = () => {
		let params = {
			dictType : 'GATE_TYPE'
		}
		_common.getDictList(params).then(res => {
			setPlaceList(res.list)
		})
	}
	
    //打开编辑页
    const openModal = (type, record,recordId, pageNo = 1, pageSize = 10) => {
		// 获取洗消点集合
		washSpotList()
		// 获取字典表对应的设备位置
		manyPlaceList()
		
        const { setFieldsValue } = props.form
        setModalType(type)
        setEditVisible(true)
        if(type === 'edit'){ // 编辑页面
            setTimeout(() => {
                setFieldsValue({
                    equipmentNo: record.equipmentNo,
					equipmentPosition: record.equipmentPosition,
					washSpotId : record.washSpotId,
                    id: record.id
                })
            }, 0)
        }else if(type === 'record'){// 查询记录
        	setDetailGateId(recordId)
		    // 获取闸机开闸数据
			let params = {
				gateId : recordId,
	            pageNo:pageNo,
	            pageSize:pageSize
			}
			_request.gateRecordList(params).then(res => {
				setTableDetailTotal(res.total || 0)
			    setGateRecordDate(res.list)
			})
        }
    }
	
	const { getFieldValue, validateFields, setFieldValue } = props.form
	
    //编辑页提交
    const editConfire = () => {
        validateFields((err, value) => {
            if (!err) {
                setConfirmLoading(true)
                if (modalType === 'add') {
                    let params = {
                        equipmentNo: getFieldValue('equipmentNo'),
						equipmentPosition : getFieldValue('equipmentPosition'),
						washSpotId : getFieldValue('washSpotId')
                    }
                    _request.addGateInfo(params).then(res => {
                        setConfirmLoading(false)
                        message.info('新增成功！')
                        setEditVisible(false)
                        getGateList()
                    })
                } else {
                    let params = {
                        equipmentNo: getFieldValue('equipmentNo'),
                        equipmentPosition : getFieldValue('equipmentPosition'),
                        washSpotId : getFieldValue('washSpotId'),
                        id: getFieldValue('id')
                    }
                    _request.editGateInfo(params).then(res => {
                        setConfirmLoading(false)
                        message.info('修改成功！')
                        setEditVisible(false)
                        getGateList()
                    })
                }
            }
        })
    }
    //编辑页取消
    const cancelEdit = () => {
        setEditVisible(false)
        setModalType('')
        setConfirmLoading(false)
    }

    //搜索
    const clickSearch = () => {
        getGateList()
    }
    //重置
    const clickReset = () => {
		setWashName('')
		setEquipmentNo('')
        getGateList('reset')
    }
	
	const { Option } = Select;
	
    return (
        <Container>
            <div className="top">
                <Button type="primary" style={{ marginRight: 10,backgroundColor: '#689F38',borderColor: '#689F38' }} onClick={openModal.bind(this, 'add')}><span className='iconfont icon-tianjia buttonIcon'></span>新增</Button>
                <Input placeholder="请输入洗消点名称" style={{ width: 200, marginRight: 10 }} value={washName} onPressEnter={clickSearch} onChange={(e) => setWashName(e.target.value)}></Input>
				<Input placeholder="请输入设备编号" style={{ width: 200, marginRight: 10 }} value={equipmentNo} onPressEnter={clickSearch} onChange={(e) => setEquipmentNo(e.target.value)}></Input>
                <Button type="primary" style={{ marginRight: 10 }} onClick={clickSearch}><span className='iconfont icon-sousuo buttonIcon'></span>搜索</Button>
                <Button type="primary" style={{ marginRight: 10 }} className='buttonReset' onClick={clickReset}><span style={{fontSize: 13}} className='iconfont icon-zhongzhi buttonIcon'></span>重置</Button>
            </div>
            <div className="bottom">
            	<DeTable columns={tableColunm} total={tableTotal} onPaginationChange={(pageNo, pageSize) => getGateList('', pageNo, pageSize)}  dataSource={tableData} />
            </div>
            <Modal
                title={modalType === 'add' ? '新增闸机' : modalType === 'edit' ? '编辑' : '闸机记录'}
                visible={editVisible}
                onCancel={cancelEdit}
                maskClosable={false}
                destroyOnClose={true}
                centered={true}
                width={'50%'}
                className='editClass'
                footer={
					modalType === 'record' ? null : 
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <Button type="primary" style={{ width: 200 }} loading={confirmLoading} onClick={editConfire}>提交</Button>
                    </div>
                }
            >
                {
                    modalType === 'add' ?
                    <Form style={{ height: 300 }}>
                        <Form.Item>
                            <span></span>
                        </Form.Item>
                        <Col span={12}>
                            <Form.Item {...formItemLayout} label="设备编号：">
                                {getFieldDecorator('equipmentNo', {
                                    rules: [{ required: true, message: '请输入设备编号！' },{ pattern: /^[^\s]*$/, message: '禁止输入空格！' }],
                                })(
                                    <Input
                                        placeholder="请输入设备编号"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>   
                            <Form.Item {...formItemLayout} label="设备位置：">
                                {getFieldDecorator('equipmentPosition', {
                                    rules: [{ required: true, message: '请输入设备位置！' }],
                                })(
                                    <Select placeholder="请选择设备位置">
                                    { 
                                    	placeList.map((item,index) => {
											// console.log(item.key);
                                    		return <Option key={item.dictValue} value={item.dictValue}>{item.dictDesc}</Option>
                                        })
                                    } 
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
						<Col span={12}>
						    <Form.Item {...formItemLayout} label="所属洗消点：">
						        {getFieldDecorator('washSpotId', {
						            rules: [{ required: true, message: '请选择洗消点！' }],
						        })(
						            <Select placeholder="请选择洗消点">
						            { 
										washList.map((item,index) => {
											return <Option key={item.key} value={item.id}>{item.name}</Option>
						                })
						            } 
									</Select>
						        )}
						    </Form.Item>
						</Col>
                    </Form>
                    : modalType === 'edit' ?
                    <Form style={{ height: 300 }}>
                        <Form.Item>
                            {getFieldDecorator('id')(
                                <span></span>
                            )}
                        </Form.Item>
                        <Col span={12}>
                            <Form.Item {...formItemLayout} label="设备编号：">
                                {getFieldDecorator('equipmentNo', {
                                    rules: [{ required: true, message: '请输入设备编号！' },{ pattern: /^[^\s]*$/, message: '禁止输入空格！' }],
                                })(
                                    <Input
                                        placeholder="请输入设备编号"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item {...formItemLayout} label="设备位置：">
                                {getFieldDecorator('equipmentPosition', {
                                    rules: [{ required: true, message: '请输入设备位置！' }],
                                })(
                                    <Select placeholder="请选择设备位置">
                                    { 
                                    	placeList.map((item,index) => {
                                    		return <Option key={item.dictValue} value={item.dictValue}>{item.dictDesc}</Option>
                                        })
                                    } 
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item {...formItemLayout} label="所属洗消点：">
                                {getFieldDecorator('washSpotId', {
                                    rules: [{ required: true, message: '请选择洗消点！' }],
                                })(
                                    <Select placeholder="请选择洗消点">
                                    { 
                        				washList.map((item,index) => {
                        					return <Option key={item.key} value={item.id}>{item.name}</Option>
                                        })
                                    } 
                        			</Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Form>:
                	 <DeTable columns={gateRecordColunm} total={tableDetailTotal} onPaginationChange={(pageNo, pageSize) => openModal('record',null, detailGateId, pageNo, pageSize)}  dataSource={gateRecordDate} />
			}
            </Modal>
        </Container>

    )
}

export default Form.create()(Gate)