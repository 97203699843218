import React, { useState, useEffect } from 'react'
import {Container} from './style'

import { Button, Input, Modal, Table, Popconfirm, Col, Form, message, Icon } from 'antd'
import DeTable from '../../../component/DeTable'
import moment from 'moment'

import StoreSettingHttp from '../../../request/demo'
import {Select} from "antd/lib/index";
import CommonHttp from "../../../request/common-request";

const _request = new StoreSettingHttp()
const Option = Select.Option;
const _commonrequest = new CommonHttp()
function Demo(props) {
    const [modalType, setModalType] = useState('') //新增or编辑
    const [editVisible, setEditVisible] = useState(false) //编辑项
    const [confirmLoading, setConfirmLoading] = useState(false) //编辑模态框加载图标
    const [pigFarmName, setPigFarmName] = useState('')
    const [washSpotName, setWashSpotName] = useState('')
    const tableColunm =
        [
            {
                title: '序号',
                key: 'number',
                render: (text, record, index) => (
                    <span>{index + 1}</span>
                )
            },
            {
                title: '洗消点',
                dataIndex: 'washSpotName',
                key: 'washSpotName',
            },
            {
                title: '物品名称',
                dataIndex: 'goodsName',
                key: 'goodsName',
            },
            {
                title: '物品类型',
                dataIndex: 'goodsType',
                key: 'goodsType',
                render: (text, record, index) => (
                    goodTypeData.map((item,index1) => {
                        if(record.goodsType === item.dictValue) {
                            return  <span>{item.dictDesc}</span>
                        }
                    })

                )
            },
            {
                title: '所属猪场',
                key: 'pigFramName',
                dataIndex: 'pigFramName',
            },
            {
                title: '操作人',
                key: 'operator',
                dataIndex: 'operator',
            },
            {
                title: '操作时间',
                key: 'createDate',
                dataIndex: 'createDate',
				render: (text, record, index) => {
					return <span>{moment(record.createDate).format('YYYY-MM-DD HH:mm:ss')}</span>;
				}
            },
            {
                title: '描述',
                dataIndex: 'remark',
                key: 'remark',
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record, index) => (
                    <span>
                        <span style={{ marginRight: 8 }} className='tableA' onClick={openModal.bind(this, 'edit', record)}>编辑</span>
                        <Popconfirm title="确认删除？" okText="删除" okType="danger" cancelText="取消" onConfirm={deleteRow.bind(this, record.id)}>
                            <span className='tableADelete'>删除</span>
                        </Popconfirm>
                    </span>
                ),
            },
        ]
    //表格结构
    const [tableTotal, setTableTotal] = useState(0)
    const [tableData, setTableData] = useState([]) //表格数据
    const [washSpotData, setWashSpotData] = useState([]) //洗消点列表
    const [goodTypeData, setGoodTypeData] = useState([]) //物资类型
    const [pigFarmData, setPigFarmData] = useState([]) //猪场列表
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    }
    const { getFieldDecorator } = props.form
    useEffect(() => {
        getAllRegister()
        getWashSpotList()
        getPigFarmList()
        getGoodTypeList()
    }, [])
    //获取物资列表
    const getAllRegister = (ButtonType, pageNo = 1, pageSize = 10) => {
        let params = {
            pigFarmName,
            washSpotName,
            pageNo,
            pageSize
        }
        if (ButtonType === 'reset') {
            params = {}
        }
        _request.findMaterialRegister(params).then(res => {
            setTableTotal(res.total || 0)
            setTableData(res.list)
        })
    }
    //获取物品类型列表
    const getGoodTypeList = () => {
        let params = {
            dictType:'GOOD_TYPE'
        }
        _commonrequest.getDictList(params).then(res => {
            setGoodTypeData(res.list)
        })
    }
    //删除最外的表格行
    const deleteRow = (id) => {
        let params = {
            id
        }
        console.log(id);
        _request.deleteMaterialRegister(params).then(res => {
            message.info('删除成功！')
            getAllRegister()
        })
    }
    //打开编辑页
    const openModal = (type, record) => {
        const { setFieldsValue } = props.form
        setModalType(type)
        setEditVisible(true)
        if(type !== 'add'){
            setTimeout(() => {
                setFieldsValue({
                    washSpotId: record.washSpotId,
                    goodsName: record.goodsName,
                    goodsType: record.goodsType,
                    remark: record.remark,
                    // pigFramId: Number(record.pigFramId),
                    pigFramId: record.pigFramId,
                    operator: record.operator,
                    id: record.id
                })
            }, 0)
        }
    }
    //获取当前登录用户下的洗消点列表
    const getWashSpotList = () => {
        let params = {
        }
        _commonrequest.getWashSpotList(params).then(res => {
            let list = []
            list = res.map(item => {
                return {
                    ...item,
                    key: item.key
                }
            })
            setWashSpotData(list)
        })
    }
    //获取当前登录用户下的猪场列表
    const getPigFarmList = () => {
        let params = {
        }
        _commonrequest.getChildRegionList(params).then(res => {
            let list = []
            list = res.map(item => {
                return {
                    ...item,
                    key: item.key
                }
            })
            setPigFarmData(list)
        })
    }
    //搜索
    const clickSearch = () => {
        getAllRegister()
    }
    //重置
    const clickReset = () => {
		setPigFarmName('')
		setWashSpotName('')
        getAllRegister('reset')
    }
    
    //编辑页提交
    const editConfire = () => {
        const { getFieldValue, validateFields } = props.form
        validateFields((err, value) => {
            if (!err) {
                setConfirmLoading(true)
                if (modalType === 'add') {
                    let params = {
                        washSpotId: getFieldValue('washSpotId'),
                        goodsName: getFieldValue('goodsName'),
                        goodsType: getFieldValue('goodsType'),
                        remark: getFieldValue('remark'),
                        pigFramId: getFieldValue('pigFramId'),
                        operator: getFieldValue('operator')
                    }
                    _request.addMaterialRegister(params).then(res => {
                        setConfirmLoading(false)
                        message.info('新增成功！')
                        setEditVisible(false)
                        getAllRegister()
                    })
                } else {
                    let params = {
                        washSpotId: getFieldValue('washSpotId'),
                        goodsName: getFieldValue('goodsName'),
                        goodsType: getFieldValue('goodsType'),
                        remark: getFieldValue('remark'),
                        pigFramId: getFieldValue('pigFramId'),
                        operator: getFieldValue('operator'),
                        id: getFieldValue('id')
                    }
                    _request.updateMaterialRegister(params).then(res => {
                        setConfirmLoading(false)
                        message.info('修改成功！')
                        setEditVisible(false)
                        getAllRegister()
                    })
                }
            }
        })
    }
    //编辑页取消
    const cancelEdit = () => {
        setEditVisible(false)
        setModalType('')
        setConfirmLoading(false)
    }

    return (
        <Container>
            <div className="top">
                <Button type="primary" style={{ marginRight: 10,backgroundColor: '#689F38',borderColor: '#689F38' }} onClick={openModal.bind(this, 'add')}><span className='iconfont icon-tianjia buttonIcon'></span>新增物资</Button>
                <Input placeholder="请输入洗消点" style={{ width: 200, marginRight: 10 }} value={washSpotName} onPressEnter={clickSearch} onChange={(e) => setWashSpotName(e.target.value)}></Input>
                <Input placeholder="请输入猪场" style={{ width: 200, marginRight: 10 }} value={pigFarmName} onPressEnter={clickSearch} onChange={(e) => setPigFarmName(e.target.value)}></Input>
                <Button type="primary" style={{ marginRight: 10 }} onClick={clickSearch}>查找</Button>
                <Button type="primary" style={{ marginRight: 10 }} className='buttonReset' onClick={clickReset}><span style={{fontSize: 13}} className='iconfont icon-zhongzhi buttonIcon'></span>重置</Button>
            </div>
            <div className="bottom">
           		<DeTable columns={tableColunm} total={tableTotal} onPaginationChange={(pageNo, pageSize) => getAllRegister('', pageNo, pageSize)}  dataSource={tableData} />
             </div>
            <Modal
                title={modalType === 'add' ? '新增物资' : '编辑'}
                visible={editVisible}
                onCancel={cancelEdit}
                maskClosable={false}
                destroyOnClose={true}
                centered={true}
                width={'50%'}
                className='editClass'
                footer={
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <Button type="primary" style={{ width: 200 }} loading={confirmLoading} onClick={editConfire}>提交</Button>
                    </div>
                }
            >
                {
                    modalType === 'add' ?
                        <Form style={{ height: 200 }}>
                            <Form.Item>
                                <span></span>
                            </Form.Item>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="洗消点：">
                                    {getFieldDecorator('washSpotId', {
                                        rules: [{ required: true, message: '请选择洗消点!' }],
                                    })(
                                        <Select >
                                            {
                                                washSpotData.map((item,index) => {
                                                    return (
                                                        <Option value={item.id} key={item.id} >{item.name}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="物品名称：">
                                    {getFieldDecorator('goodsName', {
                                        rules: [{ required: true, message: '请输入物品名称！' }],
                                    })(
                                        <Input
                                            placeholder="请输入物品名称"
                                        />,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="物品类型：">
                                    {getFieldDecorator('goodsType', {
                                        rules: [{ required: true, message: '请选择物品类型!' }],
                                    })(
                                        <Select >
                                            {
                                                goodTypeData.map((item,index) => {
                                                    return (
                                                        <Option value={item.dictValue} key={item.dictValue} >{item.dictDesc}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="描述：">
                                    {getFieldDecorator('remark', {
                                        rules: [{ required: true, message: '请输入描述！' }],
                                    })(
                                        <Input
                                            placeholder="请输入描述"
                                        />,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="猪场：">
                                    {getFieldDecorator('pigFramId', {
                                        rules: [{ required: true, message: '请选择猪场!' }],
                                    })(
                                        <Select >
                                            {
                                                pigFarmData.map((item,index) => {
                                                    return (
                                                        <Option value={item.regionId} key={item.regionId} >{item.regionName}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="操作人：">
                                    {getFieldDecorator('operator', {
                                        rules: [{ required: true, message: '请输入操作人！' }],
                                    })(
                                        <Input
                                            placeholder="请输入操作人"
                                        />,
                                    )}
                                </Form.Item>
                            </Col>
                        </Form>
                        :
                        <Form style={{ height: 200 }}>
                            <Form.Item>
                                {getFieldDecorator('id')(
                                    <span></span>
                                )}
                            </Form.Item>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="洗消点：">
                                    {getFieldDecorator('washSpotId', {
                                        rules: [{ required: true, message: '请选择洗消点!' }],
                                    })(
                                        <Select >
                                            {
                                                washSpotData.map((item,index) => {
                                                    return (
                                                        <Option value={item.id} key={item.id} >{item.name}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="物品名称：">
                                    {getFieldDecorator('goodsName', {
                                        rules: [{ required: true, message: '请输入物品名称！' }],
                                    })(
                                        <Input
                                            placeholder="请输入物品名称"
                                        />,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="物品类型：">
                                    {getFieldDecorator('goodsType', {
                                        rules: [{ required: true, message: '请选择物品类型!' }],
                                    })(
                                        <Select >
                                            {
                                                goodTypeData.map((item,index) => {
                                                    return (
                                                        <Option value={item.dictValue} key={item.dictValue} >{item.dictDesc}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="描述：">
                                    {getFieldDecorator('remark', {
                                        rules: [{ required: true, message: '请输入描述！' }],
                                    })(
                                        <Input
                                            placeholder="请输入描述"
                                        />,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="猪场：">
                                    {getFieldDecorator('pigFramId', {
                                        rules: [{ required: true, message: '请选择猪场!' }],
                                    })(
                                        <Select >
                                            {
                                                pigFarmData.map((item,index) => {
                                                    return (
                                                        <Option value={item.regionId} key={item.regionId} >{item.regionName}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="操作人：">
                                    {getFieldDecorator('operator', {
                                        rules: [{ required: true, message: '请输入操作人！' }],
                                    })(
                                        <Input
                                            placeholder="请输入操作人"
                                        />,
                                    )}
                                </Form.Item>
                            </Col>
                        </Form>
                }
            </Modal>
        </Container>

    )
}

export default Form.create()(Demo)