import React, { useEffect, useState } from 'react'

import HomeTitle from "../../component/HomeTitle";
import HomeAudit from "./subpage/audit";
import { Container } from './style'
import {Col, Form} from 'antd'
import Wash from "./subpage/wash";
import Alert from "./subpage/alert";
import Video from "./subpage/video";
import Share from "./subpage/share";
import Fast from "./subpage/fast";

function ErrorPage(props) {
    const { nextClick } = props;
    const clickDetails = (e,id,time) => {
        let param = {
            key: e
        }
        if (id || time){
            nextClick(param,id,time)
        }else {
            nextClick(param)
        }
    }
    return (
        <Container>
            <div style={{ display: 'flex' }}>
                <div className='homeCol' style={{ marginRight: 10,width: 768,height: 390 }}>
                    <HomeTitle title={'洗消记录'} iconFont={'wash'} clickDetails={clickDetails.bind(this,'148&洗消记录')}/>
                    <Wash clickDetails={clickDetails}/>
                </div>
                <div className='homeCol' style={{ marginRight: 10,width: 546,height: 390 }}>
                    <HomeTitle title={'审批记录'} iconFont={'audit'} clickDetails={clickDetails.bind(this,"146&审批管理")}/>
                    <HomeAudit/>
                </div>
                <div style={{width: 360,height: 390}} className='homeCol'>
                    <HomeTitle title={'沟通分享'} iconFont={'share'} clickDetails={clickDetails.bind(this,"152&分享沟通")} isBorder={true}/>
                    <Share/>
                </div>
            </div>
            <div style={{ display: 'flex',marginTop: 10 }}>
                <div className='homeCol' style={{ marginRight: 10,width: 768,height: 348 }}>
                    <HomeTitle title={'警报事件'} iconFont={'alert'} clickDetails={clickDetails.bind(this,"151&警报中心")} isBorder={true}/>
                    <Alert clickDetails={clickDetails}/>
                </div>
                <div className='homeCol' style={{ marginRight: 10,width: 546,height: 348 }}>
                    <HomeTitle title={'视频监控'} iconFont={'video'} clickDetails={clickDetails.bind(this,"147&视频监控")} isBorder={true}/>
                    <Video/>
                </div>
                <div style={{width: 360,height: 348}} className='homeCol'>
                    <Fast clickDetails={clickDetails}/>
                </div>
            </div>
        </Container>
    )
}

export default Form.create()(ErrorPage)