import request from '../https'

class CarRequest{
	//常驻车辆列表=========================
    getCarInfoList(params){
        return request({
            url: 'safety/carInfo/list',
            method: 'post',
            params
        })
    }
    
    
   //获取车辆关联的猪场列表
   getCarFramList(params){
        return request({
            url: 'safety/carInfo/getCarFramList',
            method: 'post',
            params
        })
    }
   
    //新增保存常驻车辆
    addCarInfo(params) {
        return request({
            url: 'safety/carInfo/add',
            method: 'POST',
            params
        });
    }
    //修改保存常驻车辆
    updateCarInfo(params) {
        return request({
            url: 'safety/carInfo/update',
            method: 'PUT',
            params
        });
    }
    //删除常驻车辆
    deleteCarInfo(params) {
        return request({
            url: 'safety/carInfo/delete/' + params.id,
            method: 'DELETE'
        });
    }
    
    
    
	//临时车辆列表=========================
    getTempCarInfoList(params){
        return request({
            url: 'safety/tempCarInfo/list',
            method: 'post',
            params
        })
    }
	//临时车辆列表=========================
    getTempCarInfoListHome(params){
        return request({
            url: 'safety/tempCarInfo/list/home',
            method: 'post',
            params
        })
    }
    
   //获取临时车辆关联的猪场列表
   getTempCarFramList(params){
        return request({
            url: 'safety/tempCarInfo/getTempCarFramList',
            method: 'post',
            params
        })
    }
    //新增保存临时车辆
    addTempCarInfo(params) {
        return request({
            // url: 'client/tempCarInfo/add',
            url: 'safety/tempCar/add',
            method: 'POST',
            params
        });
    }
    //修改保存临时车辆
    updateTempCarInfo(params) {
        return request({
            url: 'safety/tempCarInfo/update',
            method: 'PUT',
            params
        });
    }
    //删除临时车辆
    deleteTempCarInfo(params) {
        return request({
            url: 'safety/tempCarInfo/delete/' + params.id,
            method: 'DELETE'
        });
    }
    
    //车辆洗消纪录列表=========================
    getDecontaminationRecordList(params){
        return request({
            url: 'safety/decontaminationRecord/list',
            method: 'post',
            params
        })
    }
    //首页车辆洗消纪录列表=========================
    getWashCarList(params){
        return request({
            url: 'safety/wash/car/record',
            method: 'post',
            params
        })
    }
    
   
    
}

export default CarRequest