import React, { useState, useEffect } from 'react'
import {Container} from './style'

import { Button, Input, Select, Modal, Table, Popconfirm, Col, Form, message, Icon } from 'antd'

import DeTable from '../../../component/DeTable'
import moment from 'moment'

import DecontaminateHttp from '../../../request/decontaminate-request'
import CommonHttp from '../../../request/common-request'
import request from '../../../https'

const Option = Select.Option;
const _request = new DecontaminateHttp()
const _commonrequest = new CommonHttp()

function WashSet(props) {
    const [tableTotal, setTableTotal] = useState(0)
    const [modalType, setModalType] = useState('') //新增or编辑
    const [editVisible, setEditVisible] = useState(false) //编辑项
    const [qrCodePath, setQrCodePath] = useState(false) //二维码
    const [confirmLoading, setConfirmLoading] = useState(false) //编辑模态框加载图标
    const [name, setName] = useState('')
    const [regionName, setRegionName] = useState('')
    const [keyId, setKeyId] = useState('')
    const [qrImg, setQrImg] = useState('')
    
    const qrStyle ={
        color:'white',
        display:'flex',
        justifyContent:'center',
    }

    const tableColunm = 
        [
            {
                title: '序号',
                key: 'number',
                render: (text, record, index) => (
                    <span>{index + 1}</span>
                )
            },
            {
                title: '洗消点',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '所属上级',
                dataIndex: 'parentName',
                key: 'parentName',
            },
            {
                title: '所属洗消中心',
                key: 'groupName',
                dataIndex: 'groupName',
            },
            {
                title: '所属猪场',
                key: 'regionName',
                dataIndex: 'regionName',
            },
            {
                title: '操作人',
                key: 'createUser',
                dataIndex: 'createUser',
            },
            {
                title: '操作日期',
                dataIndex: 'createDate',
                key: 'createDate',
				render: (text, record, index) => {
					return <span>{moment(record.createDate).format('YYYY-MM-DD HH:mm:ss')}</span>;
				}
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record, index) => (
                    <span>
                        <a className='tableA' href='javascript:;' style={{ marginRight: 8 }} onClick={openModal.bind(this, 'edit', record)}>编辑</a>
                        <Popconfirm title="确认删除？" okText="删除" okType="danger" cancelText="取消" onConfirm={deleteRow.bind(this, record.id)}>
                            {/*<Button type="danger" size="small">删除</Button>*/}
                            <a href='javascript:;' className='tableADelete'>删除</a>
                        </Popconfirm>
                        <a className='tableA' href='javascript:;' style={{ marginLeft: 8 }} onClick={QRCode.bind(this, record)}>二维码</a>

                    </span>
                ),
            },
        ]
     //表格结构
    const [tableData, setTableData] = useState([]) //表格数据
    const [childRegionData, setChildRegionData] = useState([]) //猪场列表
    const [pigFramData, setPigFramData] = useState([]) //获取洗消点关联的猪场列表
	const [groupData, setGroupData] = useState([]) //获取分组
	const [parentData, setParentData] = useState([])
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    }
    const { getFieldDecorator } = props.form
    useEffect(() => {
        getWashSetList()
        getChildRegionList()
    }, [])
    //获取列表
    const getWashSetList = (ButtonType, pageNo = 1, pageSize = 10) => {
        let params = {
            name:name,
            regionName:regionName,
            pageNo,
            pageSize
        }
        if (ButtonType === 'reset') {
            params = {}
        }
        _request.getWashSetList(params).then(res => {
            setTableTotal(res.total || 0)
            setTableData(res.list)
        })
    }
    
    //获取猪场列表
    const getChildRegionList = () => {
        let params = {}
        _commonrequest.getChildRegionList(params).then(res => {
            setChildRegionData(res)
        })
    }
    
	//获取组列表
	const getetGroupDataList = () => {
	    let params = {}
	    _request.getetGroupDataList(params).then(res => {
	        setGroupData(res.list)
	    })
	}
	
	//获取上级列表
	const getParentList = (groupId) => {
		    let params = {
				groupId:groupId,
				id : keyId
		    }
		    _request.getParentList(params).then(res => {
				if(res.list.length<1){
					const { setFieldsValue } = props.form
					setFieldsValue({
						parentId: undefined
					})
				}
				setParentData(res.list)
		    })
		
	}
	
	
    //删除最外的表格行
    const deleteRow = (id) => {
        let params = {
            id
        }
        _request.deleteWashSet(params).then(res => {
            message.info('删除成功！')
            getWashSetList()
        })
    }
    const QRCode = (record) =>{
        setQrCodePath(true)
        setQrImg(record.qrCodePath)
    }
    //打开编辑页
    const openModal = (type, record) => {
        getetGroupDataList()
        const { setFieldsValue } = props.form
        setModalType(type)
        setEditVisible(true)
        if(type !== 'add'){
        	 //获取洗消点关联的猪场列表
            let params = {
            	washSpotId:record.id
            }
	        _request.getPigFramList(params).then(res => {
	                setFieldsValue({
	                    // pigFram:res.map(item => Number(item)),//.map(Number)
	                    pigFram:res.map(item => item),//.map(Number)
	                })
	        })
			//获取洗消点组id
			/* _request.getetGroupId(params).then(res => {
			        setFieldsValue({
			            groupId:res.map(item => Number(item)),//.map(Number)
			        })
			}) */
            setTimeout(() => {
				getParentList(record.groupId)
				setKeyId(record.id)
                setFieldsValue({
                    name: record.name,
                    longitudel: record.longitudel,
                    latitude: record.latitude,
                    address: record.address,
                    id: record.id,
					parentId: record.parentId,
					groupId:record.groupId
                })
            }, 0)
            
           
        }
        
    }
    
    //编辑页提交
    const editConfire = () => {
        const { getFieldValue, validateFields } = props.form
        validateFields((err, value) => {
            if (!err) {
                setConfirmLoading(true)
                let newData = getFieldValue('pigFram').map(item => item.toString()).join('-')
                if (modalType === 'add') {
                    let params = {
                        name: getFieldValue('name'),
                        // longitudel: getFieldValue('longitudel'),
                        // latitude: getFieldValue('latitude'),
                        address: getFieldValue('address'),
						groupId: getFieldValue('groupId'),
						parentId: getFieldValue('parentId'),
                        regionName:newData.toString()
                    }
                    _request.addWashSet(params).then(res => {
						//addGroupData(params)
                        setConfirmLoading(false)
                        message.info('新增成功！')
                        setEditVisible(false)
                        getWashSetList()
                    })
                } else {
                    let params = {
                        name: getFieldValue('name'),
                        // longitudel: getFieldValue('longitudel'),
                        // latitude: getFieldValue('latitude'),
                        address: getFieldValue('address'),
						groupId: getFieldValue('groupId'),
                        regionName:newData.toString(),
						parentId: getFieldValue('parentId'),
                        id: getFieldValue('id')
                    }
                    _request.updateWashSet(params).then(res => {
                        setConfirmLoading(false)
                        message.info('修改成功！')
                        setEditVisible(false)
                        getWashSetList()
                    })
                }
            }
        })
    }
      
//     const downLoad = (el, name) =>  {
//            if (!isElement(el)) {
//                throw new Error('an HTMLElement or SVGElement is required; got ' + el);
//            }
//            if (!name.qrCodePath) {
//                console.error("文件名为空!");
//                return;
//            }
//            var xmlns = "http://www.w3.org/2000/xmlns/";
//            var clone = el.cloneNode(true);
//            clone.setAttribute("version", "1.1");
//            if (!clone.getAttribute('xmlns')) {
//                clone.setAttributeNS(xmlns, "xmlns", "http://www.w3.org/2000/svg");
//            }
//            if (!clone.getAttribute('xmlns:xlink')) {
//                clone.setAttributeNS(xmlns, "xmlns:xlink", "http://www.w3.org/1999/xlink");
//            }
//            var svg = clone.outerHTML;
//            var uri = 'data:image/svg+xml;base64,' + window.btoa(reEncode(doctype + svg));
//            var image = new Image();
//            image.onload = function () {
//                var png = convertToPng(image, image.width, image.height);
//                var saveLink = document.createElement('a');
//                var downloadSupported = 'download' in saveLink;
//                if (downloadSupported) {
//                    saveLink.download = qrCodePath;
//                    saveLink.style.display = 'none';
//                    document.body.appendChild(saveLink);
//                    try {
//                        var blob = uriToBlob(png);
//                        var url = URL.createObjectURL(blob);
//                        saveLink.href = url;
//                        saveLink.onclick = function () {
//                            requestAnimationFrame(function () {
//                                URL.revokeObjectURL(url);
//                            })
//                        };
//                    } catch (e) {
//                        saveLink.href = uri;
//                    }
//                    saveLink.click();
//                    document.body.removeChild(saveLink);
//                }
//            };
//            image.src = uri;
//        }
//  
    
    //编辑页取消
    const cancelEdit = () => {
        setEditVisible(false)
        setModalType('')
        setConfirmLoading(false)
    }

    //二维码页面取消
    const qrCodeEdit = () =>{
        setQrCodePath(false)
    }

    //搜索
    const clickSearch = () => {
        getWashSetList()
    }
    //重置
    const clickReset = () => {
		setName('')
		setRegionName('')
        getWashSetList('reset')
    }
    return (
        <Container>
            <div className="top">
                <Button type="primary" style={{ marginRight: 10,backgroundColor: '#689F38',borderColor: '#689F38' }} onClick={openModal.bind(this, 'add')}><span className='iconfont icon-tianjia buttonIcon'></span>新增</Button>
                <Input placeholder="请输入洗消点" style={{ width: 200, marginRight: 10 }} value={name} onPressEnter={clickSearch} onChange={(e) => setName(e.target.value)}></Input>
                <Input placeholder="请输入所属猪场" style={{ width: 200, marginRight: 10 }} value={regionName} onPressEnter={clickSearch} onChange={(e) => setRegionName(e.target.value)}></Input>
                <Button type="primary" style={{ marginRight: 10 }} onClick={clickSearch}><span className='iconfont icon-sousuo buttonIcon'></span>搜索</Button>
                <Button type="primary" style={{ marginRight: 10 }} className='buttonReset' onClick={clickReset}><span style={{fontSize: 13}} className='iconfont icon-zhongzhi buttonIcon'></span>重置</Button>
            </div>
            <div className="bottom">
                <DeTable columns={tableColunm} total={tableTotal} onPaginationChange={(pageNo, pageSize) => getWashSetList('', pageNo, pageSize)} dataSource={tableData} />
            </div>
            <Modal
                style={{ textAlign: 'center' }}
                title={'二维码'}
                visible={qrCodePath}
                centered={true}
                width={'50%'}
                onCancel={qrCodeEdit}
                maskClosable={false}
                destroyOnClose={true}
                footer={null}
            >
                
                {/* <img width='100%' src={qrImg ? qrImg : } /> */}
                {
                    qrImg ?
                    <img src={qrImg} alt="" /> :
                    <div style={ qrStyle }>暂无图片</div>
				}
            </Modal>

            <Modal
                title={modalType === 'add' ? '新增洗消点' : '编辑'}
                visible={editVisible}
                onCancel={cancelEdit}
                maskClosable={false}
                destroyOnClose={true}
                centered={true}
                width={'50%'}
                className='editClass'
                footer={
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <Button type="primary" style={{ width: 200 }} loading={confirmLoading} onClick={editConfire}>提交</Button>
                    </div>
                }
            >
                {
                    modalType === 'add' ?
                    <Form style={{ height: 270 }}>
                        <Form.Item>
                            <span></span>
                        </Form.Item>
                        <Col span={12}>
                            <Form.Item {...formItemLayout} label="洗消点：">
                                {getFieldDecorator('name', {
                                    rules: [{ required: true, message: '请输入洗消点！' }],
                                })(
                                    <Input
                                        placeholder="请输入洗消点"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                        {/* <Col span={12}>*/}
                        {/*    <Form.Item {...formItemLayout} label="经度：">*/}
                        {/*        {getFieldDecorator('longitudel', {*/}
                        {/*            rules: [{ required: true, message: '请输入经度！',pattern:new RegExp(/^\d+(\.\d+)?$/, "g") }],*/}
                        {/*        })(*/}
                        {/*            <Input*/}
                        {/*                placeholder="请输入经度"*/}
                        {/*            />,*/}
                        {/*        )}*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                        {/* <Col span={12}>*/}
                        {/*    <Form.Item {...formItemLayout} label="纬度：">*/}
                        {/*        {getFieldDecorator('latitude', {*/}
                        {/*            rules: [{ required: true, message: '请输入纬度！',pattern:new RegExp(/^\d+(\.\d+)?$/, "g") }],*/}
                        {/*        })(*/}
                        {/*            <Input*/}
                        {/*                placeholder="请输入纬度"*/}
                        {/*            />,*/}
                        {/*        )}*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                        <Col span={12}>
                            <Form.Item {...formItemLayout} label="地址：">
                                {getFieldDecorator('address', {
                                    rules: [{ required: true, message: '请输入地址！' }],
                                })(
                                    <Input
                                        placeholder="请输入地址"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
							<Form.Item {...formItemLayout} label="所属猪场：">
								{getFieldDecorator('pigFram', {
									rules: [{ required: true, message: '请选择所属猪场!' }],
								})(
									<Select mode="multiple">
										{
							  				childRegionData.map((item,index) => {
								  				return (
								  					<Option value={item.regionId} key={item.regionId} >{item.regionName}</Option>
								  				)
							  				})
										}
									</Select>
								)}

							</Form.Item>
						</Col>
						
						<Col span={12}>
							<Form.Item {...formItemLayout} label="所属洗消中心：">
								{getFieldDecorator('groupId', {
									rules: [{ required: true, message: '请选择所属洗消中心!' }],
								})(
									<Select onChange={getParentList}>
										{
							  				groupData.map((item,index) => {
								  				return (
								  					<Option value={item.id} key={item.id} >{item.groupName}</Option>
								  				)
							  				})
										}
									</Select>
								)}
							</Form.Item>
						</Col>
						
						<Col span={12}>
							<Form.Item {...formItemLayout} label="所属上级：">
								{getFieldDecorator('parentId', {
									rules: [{ required: false, message: '请选择所属上级!' }],
								})(
									<Select>
										{
							  				parentData.map((item,index) => {
								  				return (
								  					<Option value={item.id} key={item.id} >{item.name}</Option>
								  				)
							  				})
										}
									</Select>
								)}
							</Form.Item>
						</Col>
						
                    </Form>
                    :
                    <Form style={{ height: 270 }}>
                        <Form.Item>
                            {getFieldDecorator('id')(
                                <span></span>
                            )}
                        </Form.Item>
                        <Col span={12}>
                            <Form.Item {...formItemLayout} label="洗消点：">
                                {getFieldDecorator('name', {
                                    rules: [{ required: true, message: '请输入洗消点！' }],
                                })(
                                    <Input
                                        placeholder="请输入洗消点"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                        {/* <Col span={12}>*/}
                        {/*    <Form.Item {...formItemLayout} label="经度：">*/}
                        {/*        {getFieldDecorator('longitudel', {*/}
                        {/*            rules: [{ required: true, message: '请输入经度！',pattern:new RegExp(/^\d+(\.\d+)?$/, "g") }],*/}
                        {/*        })(*/}
                        {/*            <Input*/}
                        {/*                placeholder="请输入经度"*/}
                        {/*            />,*/}
                        {/*        )}*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                        {/* <Col span={12}>*/}
                        {/*    <Form.Item {...formItemLayout} label="纬度：">*/}
                        {/*        {getFieldDecorator('latitude', {*/}
                        {/*            rules: [{ required: true, message: '请输入纬度！',pattern:new RegExp(/^\d+(\.\d+)?$/, "g") }],*/}
                        {/*        })(*/}
                        {/*            <Input*/}
                        {/*                placeholder="请输入纬度"*/}
                        {/*            />,*/}
                        {/*        )}*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                        <Col span={12}>
                            <Form.Item {...formItemLayout} label="地址：">
                                {getFieldDecorator('address', {
                                    rules: [{ required: true, message: '请输入地址！' }],
                                })(
                                    <Input
                                        placeholder="请输入地址"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
							<Form.Item {...formItemLayout} label="所属猪场：">
								{getFieldDecorator('pigFram', {
									//initialValue:pigFramData.map(Number),//.map(Number)
									rules: [{ required: true, message: '请选择所属猪场!' }],
								})(
									<Select  mode="multiple">
										{
							  				childRegionData.map((item,index) => {
								  				return (
								  					<Option value={item.regionId} key={item.regionId} >{item.regionName}</Option>
								  				)
							  				})
										}
									</Select>
								)}

							</Form.Item>
						</Col>
						
						<Col span={12}>
							<Form.Item {...formItemLayout} label="所属洗消中心：">
								{getFieldDecorator('groupId', {
									rules: [{ required: true, message: '请选择洗消中心!' }],
								})(
									<Select onChange={getParentList}>
										{
							  				groupData.map((item,index) => {
								  				return (
								  					<Option value={item.id} key={item.id} >{item.groupName}</Option>
								  				)
							  				})
										}
									</Select>
								)}
							</Form.Item>
						</Col>
						
						<Col span={12}>
							<Form.Item {...formItemLayout} label="所属上级：">
								{getFieldDecorator('parentId', {
									rules: [{ required: false, message: '请选择所属上级!' }],
								})(
									<Select>
										{
							  				parentData.map((item,index) => {
								  				return (
								  					<Option value={item.id} key={item.id} >{item.name}</Option>
								  				)
							  				})
										}
									</Select>
								)}
							</Form.Item>
						</Col>
						
                    </Form>
                }
            </Modal>
        </Container>

    )
}

export default Form.create()(WashSet) 