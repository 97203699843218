import request from '../https'

class ShowerRequest {
    //智能花洒列表
    getSmartShowerList(params){
        return request({
            url: 'safety/smartshower/list',
            method: 'post',
            params
        })
    }
    //新增智能花洒
    addSmartShowerList(params){
        return request({
            url: 'safety/smartshower/add',
            method: 'post',
            params
        })
    }
    //修改智能花洒
    updateSmartShowerMeter(params){
        return request({
            url: 'safety/smartshower/update',
            method: 'post',
            params
        })
    }
    //删除智能花洒
    deleteSmartShowerMeter(params){
        return request({
            url: 'safety/smartshower/delete',
            method: 'post',
            params
        })
    }
    //查询智能花洒猪场参数设置
    getSmartShowerFarm(params){
        return request({
            url: 'safety/smartshowerFarm/info',
            method: 'post',
            params
        })
    }
    //编辑智能花洒猪场参数设置
    updateSmartShowerFarm(params){
        return request({
            url: 'safety/smartshowerFarm/update',
            method: 'post',
            params
        })
    }
    //编辑智能花洒猪场参数设置
    getLogList(params){
        return request({
            url: 'safety/peopleSmartshower/logList',
            method: 'post',
            params
        })
    }
    //推送
    pushAgain(params){
        return request({
            url: 'safety/peopleSmartshower/pushAgain',
            method: 'post',
            data:params
        })
    }
}

export default ShowerRequest