import React, {useState, useEffect} from 'react'

import { Container } from './style'

import {Steps, Icon, InputNumber, Select, Button, Switch, Modal, message, Form} from 'antd'

import DecontaminateHttp from '../../request/decontaminate-request'
import CommonHttp from '../../request/common-request'
import DeTable from "../DeTable";
const _commonrequest = new CommonHttp()
const _request = new DecontaminateHttp()

const { Option } = Select

const { Step } = Steps

function FlowTree(props) {
	
    const { title, list, onChange, openCheckItem, washSpotId } = props

    const [flowList, setFlowList] = useState([])
    const [isShower, setIsShower] = useState(false)
    const [isSmartShowerData, setIsSmartShower] = useState([])
    const [flowTypeData, setFlowTypeData] = useState([]) //流程类型列表
    const [isDisinfectDev, setIsDisinfectDev] = useState(false)
    const [ruleVisible, setRuleVisible] = useState(false)
    const [ruleType, setRuleType] = useState('1')
    const [ruleIndex, setRuleIndex] = useState(0)
    const [tableTypeData, setTableTypeData] = useState([])
    const [ultravioletType, setUltravioletType] = useState(false)
    const [ozoneType, setOzoneType] = useState(false)

    const numberStyle = {
        width: '100%'
    }
    const borderRed = {
        width: '100%',
        border: 'red 1px solid'
    }
    const ultravioletColUnm = [
        {
            title: '类型',
            key: 'name',
            dataIndex: 'name'
        },
        {
            title: '紫外线值（μW.s/cm²）',
            key: 'disinfectValue',
            render: (text, record, index) => (
                <div>
                    <InputNumber style={record.disinfectValue !== null?numberStyle:borderRed} value={record.disinfectValue} onChange={inputChange.bind(this,index)}/>
                    {
                        record.disinfectValue !== null?'':<div style={{color: 'red',fontSize: '12px'}}>规则设置不能为空!</div>
                    }
                </div>
            )
        },
        {
            title: '持续时间（分钟）',
            key: 'date',
            render: (text, record, index) => (
                <div>
                    <InputNumber style={record.date !== null?numberStyle:borderRed} value={record.date} onChange={dateChange.bind(this,index)}/>
                    {
                        record.date !== null?'':<div style={{color: 'red',fontSize: '12px'}}>规则设置不能为空!</div>
                    }
                </div>
            )
        },
    ]
    const ozoneColUnm = [
        {
            title: '类型',
            key: 'name',
            dataIndex: 'name'
        },
        {
            title: '臭氧浓度（ppm）',
            key: 'disinfectValue',
            render: (text, record, index) => (
                <div>
                    <InputNumber style={record.disinfectValue !== null?numberStyle:borderRed} value={record.disinfectValue} onChange={inputChange.bind(this,index)}/>
                    {
                        record.disinfectValue !== null?'':<div style={{color: 'red',fontSize: '12px'}}>规则设置不能为空!</div>
                    }
                </div>
            )
        },
        {
            title: '持续时间（分钟）',
            key: 'date',
            render: (text, record, index) => (
                <div>
                    <InputNumber style={record.date !== null?numberStyle:borderRed} value={record.date} onChange={dateChange.bind(this,index)}/>
                    {
                        record.date !== null?'':<div style={{color: 'red',fontSize: '12px'}}>规则设置不能为空!</div>
                    }
                </div>
            )
        },
    ]

    useEffect(() => {
        setFlowList(list)
        list.map((item,index) => {
            if (item.workflowName === '2'){
                if (item.workflowTemperature){
                    setIsShower(true)
                }
            }
        })
        if (title === '2'){
            isSmartShower()
        }
        if (title === '3'){
            isDisinfectDevice()
        }
        if (flowTypeData.length === 0) {
            getDictList()
        }
    }, [list])
    const isSmartShower = () => {
        _commonrequest.isSmartShower({washSpotId}).then(res =>{
            setIsSmartShower(res)
        })
    }
    const isDisinfectDevice = () => {
        _commonrequest.isDisinfectDevice({washSpotId}).then(res =>{
            setIsDisinfectDev(res)
        })
    }
    const handleTimeChange = (index, val) => {
        let list = [...flowList]
        list[index].workflowTime = val
        setFlowList(list)
        onChange(list)
    }
    const inputChange = (index,e) => {
        let arr = tableTypeData;
        arr[index].disinfectValue = e
        setTableTypeData([...arr])
        // let arr = JSON.parse(JSON.stringify(tableTypeData));
        // arr[index].disinfectValue = e
        // setTableTypeData(arr)
    }
    const dateChange = (index,e) => {
        let arr = tableTypeData;
        arr[index].date = e
        setTableTypeData([...arr])
    }
    const handleTempChange = (index, val) => {
        let list = [...flowList]
        list[index].workflowTemperature = val
        setFlowList(list)
        onChange(list)
    }
    const chooseFlowType = (index, val) => {
        let list = [...flowList]
        list[index].workflowName = val
        if (title === '1'){
            list[index].workflowTemperature = 70
        }
        setFlowList(list)
        onChange(list)
    }
    //获取流程类型列表
    const getDictList = () => {
        let params = {
            dictType: title === '1' ? 'CAR_WORKFLOW_TYPE' : title === '2' ? 'PEOPLE_WORKFLOW_TYPE' : title === '3' ? 'GOOD_WORKFLOW_TYPE' : '',
            isDefault: '1'//如果IsDefault不是空的，用来查询洗消类型不为0病毒检测的数据
        }
        _commonrequest.getDictList(params).then(res => {
            setFlowTypeData(res.list)
        })
    }
    //增加流程节点
    const addFlow = () => {
        let list = [...flowList]
        if (title === '3'){
            if (isDisinfectDev.isUltravioletSensor || isDisinfectDev.isOzoneSensor){
                list.push({
                    workflowName: '4',
                    workflowTime: '0',
                    isultraviolet: false,
                    isozone: false
                })
            }else {
                return message.warning('当前洗消点没有物资消毒检测设备，不可添加物资消毒流程！')
            }
        }else {
            list.push({
                workflowName: '2',
                workflowTime: '20'
            })
        }
        setFlowList(list)
        onChange(list)
    }
    //删除节点
    const deleteFlow = (index) => {
        let list = [...flowList]
        list.splice(index, 1)
        setFlowList(list)
        onChange(list)
    }
    //点击开关回调
    const selectSwitch = (res) => {
        if (res){
            flowList.map((item,index) => {
                if (item.workflowName === '2'){
                    flowList[index].workflowTemperature = isSmartShowerData.temperature
                    flowList[index].workflowTime = isSmartShowerData.time
                }
            })
            onChange(flowList)
            setIsShower(res)
        }else{
            setIsShower(res)
            flowList.map((item,index) => {
                if (item.workflowName === '2'){
                    flowList[index].workflowTemperature = null
                }
            })
            onChange(flowList)
        }
    }
    //紫外线按钮
    const purpleString = (i,type,id,e) => {
        let list = [...flowList];
        if (e){
            getGoodsType2(type,i)
        }else{
            list[i].ultravioletList = []
            list[i].isultraviolet = false
            setFlowList(list)
            onChange(list)
        }
    }
    //臭氧按钮
    const ozoneChange = (i,type,id,e) => {
        let list = [...flowList];
        if (e){
            getGoodsType2(type,i)
        }else{
            list[i].ozoneList = []
            list[i].isozone = false
            setFlowList(list)
            onChange(list)
        }
    }
    const getGoodsType2 = (type,i) => {
        _request.getGoodsType({washSpotId}).then(res =>{
            let list = [...flowList];
            let arr = []
            arr = res.map((item,index) => {
                let obj = {}
                obj.goodsTypeId = item.id
                obj.goodsTypeName = item.name
                if (type === '1'){
                    obj.disinfectValue = "20"
                    obj.workflowTime = 60
                }else {
                    obj.disinfectValue = "100000"
                    obj.workflowTime = 30
                }
                return obj
            })
            if (type === '1'){
                list[i].ozoneList = arr
                list[i].isozone = true
            }else {
                list[i].ultravioletList = arr
                list[i].isultraviolet = true
            }
            setFlowList(list)
            onChange(list)
        })
    }
    const cancelDetail = () => {
        setRuleVisible(false)
    }
    const ruleConFire = () => {
        let list = [...flowList]
        let arr = tableTypeData.map((item,index) => {
            let obj = {}
            obj.goodsTypeId = item.id
            obj.disinfectValue = item.disinfectValue
            obj.goodsTypeName = item.name
            obj.workflowTime = item.date
            return obj
        })
        for (let i in arr){
            if (arr[i].disinfectValue === null || arr[i].workflowTime === null){
                return
            }
        }
        if (ruleType === '1'){
            setOzoneType(true)
            list[ruleIndex].ozoneList = arr
        }else {
            setUltravioletType(true)
            list[ruleIndex].ultravioletList = arr
        }
        setFlowList(list)
        setRuleVisible(false)
        onChange(flowList)
    }
    //打开规则设置
    const openRuleModal = (e,i,id) => {
        setRuleType(e)
        setRuleIndex(i)
        getGoodsRules(e,id,i)
        setRuleVisible(true)
    }
    //获取消毒规则
    const getGoodsRules = (e,id,index) => {
        //判断是否有id
        if (id && !ozoneType && e === '1'){
            _request.getGoodsRules({workflowId:id,disinfectType: e}).then(res =>{
                getGoodsType(res,e,index)
            })
        }else if(id && !ultravioletType && e === '2'){
            _request.getGoodsRules({workflowId:id,disinfectType: e}).then(res =>{
                getGoodsType(res,e,index)
            })
        }else {
            let arr =[]
            if (e === '1'){
                arr = flowList[index].ozoneList.map((item,i) => {
                    let obj = {}
                    obj.id = item.goodsTypeId
                    obj.disinfectValue = item.disinfectValue
                    obj.name = item.goodsTypeName
                    obj.date = item.workflowTime
                    return obj
                })
            }
            if (e === '2'){
                arr = flowList[index].ultravioletList.map((item,i) => {
                    let obj = {}
                    obj.id = item.goodsTypeId
                    obj.disinfectValue = item.disinfectValue
                    obj.name = item.goodsTypeName
                    obj.date = item.workflowTime
                    return obj
                })
            }
            setTableTypeData(arr)
        }
    }
    //获取物品类型
    const getGoodsType = (arr,e,i) => {
        _request.getGoodsType({washSpotId}).then(res =>{
            let list = res;
            arr.forEach((item,index) =>{
                if (item.goodsTypeId === list[index].id){
                    list[index].date = item.workflowTime
                    list[index].disinfectValue = item.disinfectValue
                    list[index].goodsTypeName = item.goodsTypeName
                }
            })
            list.forEach((item,index) => {
                if (!item.disinfectValue){
                    if (e === '1'){
                        list[index].disinfectValue = "20"
                    }else {
                        list[index].disinfectValue = "100000"
                    }
                }
                if (!item.date){
                    if (e === '1'){
                        list[index].date = 60
                    }else {
                        list[index].date = 30
                    }
                }
            })
            setTableTypeData(list)
        })
    }

    return (
        <Container className="flowPic">
            <div className="title">{title === '1' ? '车辆' : title === '2' ? '人员' : title === '3' ? '物料' : ''}</div>
            <Steps direction="vertical">
                {
                    flowList.map((item, index) => {
                        return <Step status="process" key={index} icon={<div className="stepIcon"><span>{index + 1}</span><Icon className="close" onClick={deleteFlow.bind(this, index)} type="close" /></div>} title={
                            <Select style={{ width: 90 }} size="small" placeholder="请选择流程" value={item.workflowName} onChange={chooseFlowType.bind(this, index)}>
                                {
                                    flowTypeData.map((item, index) => {
                                        return (
                                            <Option value={item.dictValue} key={item.dictValue} >{item.dictDesc}</Option>
                                        )
                                    })
                                }
                            </Select>
                        } description={
                            <React.Fragment>
                                {
                                    item.workflowName === '4' && title === '3' ?
                                    <div>
                                        {
                                            isDisinfectDev.isUltravioletSensor?
                                                <div style={{color: 'rgba(0,0,0,0.8)'}}>紫外线：<Switch size="small" checkedChildren='开' unCheckedChildren='关' checked={item.isultraviolet} onChange={purpleString.bind(this,index,'2',item.id)} /></div>:''
                                        }
                                        {
                                            isDisinfectDev.isUltravioletSensor && item.isultraviolet?
                                                <Button size="small" style={{marginTop: 3, width: 90, backgroundColor: 'white', borderColor: '#E0E0E0',marginBottom: 10}} onClick={openRuleModal.bind(this,'2',index,item.id)}>规则设置</Button>:''
                                        }
                                        {
                                            isDisinfectDev.isOzoneSensor?
                                                <div style={{color: 'rgba(0,0,0,0.8)'}}>臭氧：&emsp;<Switch size="small" checkedChildren='开' unCheckedChildren='关' checked={item.isozone} onChange={ozoneChange.bind(this,index,'1',item.id)} /></div>:''
                                        }
                                        {
                                            isDisinfectDev.isOzoneSensor && item.isozone?
                                                <Button size="small" style={{marginTop: 3, width: 90, backgroundColor: 'white', borderColor: '#E0E0E0'}} onClick={openRuleModal.bind(this,'1',index,item.id)}>规则设置</Button>:''
                                        }
                                    </div>
                                    :<div>
                                        <InputNumber style={{width: 60, marginRight: 2}} defaultValue={0} value={item.workflowTime} size="small" disabled={isShower?true:false} onChange={handleTimeChange.bind(this, index)}></InputNumber><span>分钟</span>
                                    </div>
                                }
                                {
                                    item.workflowName === '8' || ( item.workflowName === '2' && isShower ) ?
                                    <React.Fragment>
                                        {/*<br/>*/}
                                        <InputNumber style={{ width: 60, marginRight: 2, marginTop: 3 }} defaultValue={0} value={item.workflowTemperature} disabled={isShower?true:false} size="small" onChange={handleTempChange.bind(this, index)}></InputNumber><span>℃</span>
                                    </React.Fragment>
                                    : ''
                                }
                                {/*<br/>*/}
                                {
                                    (item.workflowName === '2' || item.workflowName === '8') && title === '1' ? 
                                    <Button size="small" style={{marginTop: 3, width: 90, backgroundColor: 'white', borderColor: '#E0E0E0'}} type={item.id ? 'primary' : 'dashed'} onClick={openCheckItem.bind(this, item.id)}>检查项设置</Button>
                                    : (item.workflowName === '6'  && title === '2' ? 
                                    <Button size="small" style={{marginTop: 3, width: 90, backgroundColor: 'white', borderColor: '#E0E0E0'}} type={item.id ? 'primary' : 'dashed'} onClick={openCheckItem.bind(this, item.id)}>检查项设置</Button>
									: (isSmartShowerData.isSmartShower && title === '2' && item.workflowName === '2'  ?
                                    <div style={{color: 'rgba(0,0,0,0.8)'}}>智能花洒：<Switch size="small" checkedChildren='开' unCheckedChildren='关' defaultChecked={isShower} onChange={selectSwitch} /></div>
                                    :'')
                                    )
                                }
                            </React.Fragment>
                        }/>
                    })
                }
                <Step status="wait" title={flowList.length === 0 ? '新增流程' : ''} key={-1} icon={<Icon className="addFlow" onClick={addFlow} type="plus-circle" />} />
            </Steps>
            <Modal
                title={'规则设置'}
                visible={ruleVisible}
                onCancel={cancelDetail}
                maskClosable={false}
                destroyOnClose={true}
                centered={true}
                width={'50%'}
                className='detailClass'
                footer={
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <Button type="primary" style={{ width: 200 }} onClick={ruleConFire}>确认</Button>
                    </div>
                }
            >
                {
                    ruleType === '2'?
                        <div style={{ height: 300,overflow: 'auto' }}>
                            <DeTable columns={ultravioletColUnm} dataSource={tableTypeData} isPagination/>
                        </div>
                        :
                        <div style={{ height: 300,overflow: 'auto' }}>
                            <DeTable columns={ozoneColUnm} dataSource={tableTypeData} isPagination/>
                        </div>
                }
            </Modal>
        </Container>
    )
}


export default FlowTree