import React, {useEffect, useState} from "react";
import {Container} from './style'
import {Form, Tabs, Input, DatePicker, Button, Modal, Steps, Select} from "antd";
import DeTable from "../../component/DeTable";
import {getPeriodTime, stringFormat, VxTimeFormat} from "../../utils/homeFormat";
import WashTotalRequest from "../../request/washTotal-request";
import moment from "moment";

const _request = new WashTotalRequest();

const { RangePicker } = DatePicker;

const {TabPane} = Tabs
const marginTop10 = {marginTop: 10}
const colorBlack = {color: 'rgba(0, 0, 0, 0.9)'}
const CutOff = {borderTop: '1px solid #E5E6EA', width: 520, marginLeft: -24, marginTop: 24}
const { Step } = Steps;
function WashTotal(props){
    const { getFieldDecorator,setFieldsValue,getFieldValue } = props.form
    const { nextClick } = props
    useEffect(()=>{
        getPersonList();
        getVehicleList();
        getGoodsList();
    },[])
    const [vehicleData, setVehicleData] = useState([]) //表格数据
    const [personData, setPersonData] = useState([]) //表格数据
    const [goodsData, setGoodsData] = useState([]) //表格数据
    const [goodsTotal, setGoodsTotal] = useState([]) //表格数据
    const [personTotal, setPersonTotal] = useState([]) //表格数据
    const [vehicleTotal, setVehicleTotal] = useState([]) //表格数据
    const [startTime, setStartTime] = useState(moment().startOf('day'))
    const [endTime, setEndTime] = useState(moment().endOf('day'))
    const [washDetailList, setWashDetailList] = useState([])
    const [imagePath, setImagePath] = useState(false) //图片查看
    const [imgFace, setImgFace] = useState('')
    const [imagPath, setImagPath] = useState('')
    const [extractWork, setExtractWork] = useState('')
    const [type,setType] = useState('')
    const [imgVehicle, setImgVehicle] = useState({})
    const [detailId, setDetailId] = useState('')
    const [stepCurrent, setStepCurrent] = useState(0)

    //车辆
    const vehicleColUnm = [
        {
            title: '序号',
            key: 'number',
            render: (text, record, index) => (
                <span>{index + 1}</span>
            )
        },
        {
            title: '日期',
            key: 'createDate',
            dataIndex: 'createDate',
            render: (text, record, index) => (
                <span>{moment(text).format("YYYY-MM-DD HH:mm:ss")}</span>
            )
        },
        {
            title: '车牌号',
            key: 'name',
            dataIndex: 'name'
        },
        {
            title: '洗消点名称',
            key: 'washSpotName',
            dataIndex: 'washSpotName',
            render: (text, record, index) => (
                stringFormat(text,9)
            )
        },
        {
            title: '流程',
            key: 'workflowName',
            dataIndex: 'workflowName',
        },
        {
            title: '位置',
            key: 'videoName',
            dataIndex: 'videoName',
        },
        {
            title: '操作',
            key: 'imgPath',
            dataIndex: 'imgPath',
            render: (imgPath, record) => (
                <span className='tableA' onClick={vehicleClick.bind(this, imgPath, record)}>详情</span>
            )
        },
    ]

    //人员
    const personColUnm = [
        {
            title: '序号',
            key: 'number',
            render: (text, record, index) => (
                <span>{index + 1}</span>
            )
        },
        {
            title: '日期',
            key: 'createDate',
            dataIndex: 'createDate',
            render: (text, record, index) => (
                <span>{moment(text).format("YYYY-MM-DD HH:mm:ss")}</span>
            )
        },
        {
            title: '名字',
            key: 'name',
            dataIndex: 'name'
        },
        {
            title: '洗消点名称',
            key: 'washSpotName',
            dataIndex: 'washSpotName',
            render: (text, record, index) => (
                stringFormat(text,9)
            )
        },
        {
            title: '流程',
            key: 'workflowName',
            dataIndex: 'workflowName',
        },
        {
            title: '位置',
            key: 'videoName',
            dataIndex: 'videoName',
        },
        {
            title: '操作',
            key: 'imgPath',
            dataIndex: 'imgPath',
            render: (imgPath, record) => (
                <span className='tableA' onClick={personClick.bind(this, imgPath, record)}>详情</span>
            )
        },
    ]
    //物资
    const goodsColUnm = [
        {
            title: '序号',
            key: 'number',
            render: (text, record, index) => (
                <span>{index + 1}</span>
            )
        },
        // {
        //     title: '日期',
        //     key: 'createDate',
        //     dataIndex: 'createDate',
        //     render: (text, record, index) => (
        //         <span>{moment(text).format("YYYY-MM-DD HH:mm:ss")}</span>
        //     )
        // },
        // {
        //     title: '物品名称',
        //     key: 'name',
        //     dataIndex: 'name'
        // },
        {
            title: '洗消点名称',
            key: 'washSpotName',
            dataIndex: 'washSpotName',
            render: (text, record, index) => (
                stringFormat(text,9)
            )
        },
        {
            title: '批次数',
            key: 'allCount',
            dataIndex: 'allCount',
        },
        // {
        //     title: '合格/不合格/消毒中',
        //     key: 'qualified',
        //     dataIndex: 'qualified',
        //     render: (imgPath, record) => (
        //         <div>
        //             <span>{record.qualifiedCount}</span>
        //             /
        //             <span style={record.unqualifiedCount === 0?{}:{color: 'red'}}>{record.unqualifiedCount}</span>
        //             /
        //             <span>{record.ongoingCount}</span>
        //         </div>
        //     )
        // },
        {
            title: '合格',
            key: 'qualifiedCount',
            dataIndex: 'qualifiedCount',
            render: (imgPath, record) => (
                <div>
                    <span className='tableA' style={{cursor: 'pointer'}} onClick={resultNext.bind(this,record,'1')}>{record.qualifiedCount}</span>
                </div>
            )
        },
        {
            title: '不合格',
            key: 'unqualifiedCount',
            dataIndex: 'unqualifiedCount',
            render: (imgPath, record) => (
                <div>
                    <span style={record.unqualifiedCount === 0 ? {
                        cursor: 'pointer',
                        textDecoration: 'underline'
                    } : {color: 'red', cursor: 'pointer', textDecoration: 'underline'}}
                          onClick={resultNext.bind(this, record, '0')}>{record.unqualifiedCount}</span>
                </div>
            )
        },
        {
            title: '消毒中',
            key: 'ongoingCount',
            dataIndex: 'ongoingCount',
            render: (imgPath, record) => (
                <div>
                    <span>{record.ongoingCount}</span>
                </div>
            )
        },
        {
            title: '操作',
            key: 'imgPath',
            dataIndex: 'imgPath',
            render: (imgPath, record) => (
                <span className='tableA' onClick={goodsClick.bind(this, record)}>详情</span>
            )
        },
    ]

    //获取人员列表数据
    const getPersonList = () => {
        let params = {
            beginDate: moment(startTime).format('YYYY-MM-DD'),
            endDate:  moment(endTime).format('YYYY-MM-DD')
        }
        _request.peopleWash(params).then(res => {
            setPersonData(res.list)
            setPersonTotal(res.total)
        })
    }
    //获取车辆列表
    const getVehicleList = () => {
        let params = {
            beginDate: moment(startTime).format('YYYY-MM-DD'),
            endDate:  moment(endTime).format('YYYY-MM-DD')
        }
        _request.carWash(params).then(res => {
            setVehicleData(res.list)
            setVehicleTotal(res.total)
        })
    }
    //获取物资列表
    const getGoodsList = () => {
        let params = {
            beginDate: moment(startTime).format('YYYY-MM-DD'),
            endDate:  moment(endTime).format('YYYY-MM-DD')
        }
        _request.goodsWash(params).then(res => {
            setGoodsData(res.list)
            setGoodsTotal(res.total)
        })
    }

    //获取人员流程
    const peopleWashDetail = (val) =>{
        let param = {
            id: val.personId,
            beginDate: moment(val.createDate).format("YYYY-MM-DD")
        }
        _request.peopleWashDetail(param).then(res =>{
            let arr = []
            let list = deWeightThree(res.list)
            list.map((item,index,list) => {
                item.time = index > 0 ? getPeriodTime(item,list[index-1]) : '';
                arr.splice(0,0,item)
            })
            arr.forEach((item,index) =>{
                if (item.createDate === val.createDate){
                    setStepCurrent(index)
                }
            })
            setWashDetailList(arr)
        })
    }

    const deWeightThree = (arr) => {
        let map = new Map();
        for (let item of arr) {
            if (!map.has(moment(item.createDate).format("YYYY-MM-DD HH:mm"))) {
                map.set(moment(item.createDate).format("YYYY-MM-DD HH:mm"), item);
            }
        }
        return [...map.values()];
    }

    //获取车辆流程
    const carWashDetail = (val) =>{
        let param = {
            id: val.carId,
            beginDate: moment(val.createDate).format("YYYY-MM-DD")
        }
        _request.carWashDetail(param).then(res =>{
            let arr = []
            let list = deWeightThree(res.list)
            list.map((item,index,list) => {
                item.time = index > 0 ? getPeriodTime(item,list[index-1]) : '';
                arr.splice(0,0,item)
            })
            arr.forEach((item,index) =>{
                if (item.createDate === val.createDate){
                    setStepCurrent(index)
                }
            })
            setWashDetailList(arr)
        })
    }

    const vehicleClick = (imgPath,record) => {
        setDetailId(record.carId)
        carWashDetail(record)
        setType('vehicle')
        setImgVehicle(record)
        setImagePath(true)
        setTimeout(()=>{
            setFieldsValue({
                time: moment(record.createDate)
            })
        },0)
        setImagPath(imgPath)
        const extractWork = record.workflowName
        setExtractWork(extractWork.substring(0,4))
    }
    const personClick = (imgPath,record) => {
        setDetailId(record.personId)
        peopleWashDetail(record)
        setType('people')
        setTimeout(()=>{
            setFieldsValue({
                time: moment(record.createDate)
            })
        },0)
        setImgFace(record)
        setImagePath(true)
        setImagPath(imgPath)
        const extractWork = record.workflowName
        setExtractWork(extractWork.substring(0,4))
    }
    const resultNext = (e,type) => {
        let param = {
            key: '148&洗消记录'
        }
        let washId = '3&'+e.washSpotId+'&'+type
        nextClick(param,washId,startTime,endTime)
    }
    const goodsClick = (e) => {
        let param = {
            key: '148&洗消记录'
        }
        nextClick(param,'3&'+e.washSpotId,startTime,endTime)
    }

    //搜索
    const clickSearch = () => {
        getPersonList()
        getVehicleList()
        getGoodsList()
    }

    const imageEdit = () =>{
        setImagePath(false)
        setType('vehicle')
        setImgVehicle({})
        setImagPath('')
        setExtractWork('')
        setImgFace('')
    }
    const rangeTimeChange = (date) => {
        if (date) {
            if (date[0] != undefined) {
                setStartTime(date[0]);
            } else {
                setStartTime('');
            }
            if (date[1] != undefined) {
                setEndTime(date[1]);
            } else {
                setEndTime('');
            }
        }
    }
    const dateChange = (e) =>{
        let param = {}
        if (type === 'people'){
            param.createDate=e;
            param.personId=detailId
            peopleWashDetail(param)
        }else{
            param.createDate=e;
            param.carId=detailId
            carWashDetail(param)
        }
    }

    const upDayClick = () =>{
        let date = getFieldValue('time')
        let time = moment(date).startOf('day').subtract(1, 'days')
        setFieldsValue({
            time
        })
        let param = {
            createDate: time
        }
        if (type === 'people'){
            param.personId=detailId
            peopleWashDetail(param)
        }else{
            param.carId=detailId
            carWashDetail(param)
        }
    }
    const nextDayClick = () =>{
        let date = getFieldValue('time')
        let time = moment(date).startOf('day').add(1, 'days')
        setFieldsValue({
            time
        })
        let param = {
            createDate: time
        }
        if (type === 'people'){
            param.personId=detailId
            peopleWashDetail(param)
        }else{
            param.carId=detailId
            carWashDetail(param)
        }
    }

    let nextDayDate = moment(getFieldValue('time')).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD')?true:false

    return (
        <Container>
            <RangePicker
                style={{marginRight: 10}}
                value={startTime === '' || endTime === '' ? null : [startTime, endTime]}
                onChange={rangeTimeChange}
                format="YYYY-MM-DD"
            />
            <Button type="primary" style={{ marginRight: 10 }} onClick={clickSearch}><span className='iconfont icon-sousuo buttonIcon'></span>搜索</Button>
            <Tabs defaultActiveKey="1">
                <TabPane tab="人员统计" key="1">
                    <div className='total'>合计：{personTotal}</div>
                    <DeTable columns={personColUnm} dataSource={personData}/>
                </TabPane>
                <TabPane tab="车辆统计" key="2" style={{marginLeft: 20}}>
                    <div className='total'>合计：{vehicleTotal}</div>
                    <DeTable columns={vehicleColUnm} dataSource={vehicleData}/>
                </TabPane>
                <TabPane tab="物资统计" key="3" style={{marginLeft: 20}}>
                    <div className='total'>合计：{goodsTotal}</div>
                    <DeTable columns={goodsColUnm} dataSource={goodsData}/>
                </TabPane>
            </Tabs>

            <Modal
                title={type === 'people'?'人员详情':'车辆详情'}
                visible={imagePath}
                centered={true}
                width={'520px'}
                onCancel={imageEdit}
                maskClosable={false}
                destroyOnClose={true}
                footer={null}
            >
                {
                    type === 'people'?
                        <div style={{ display: 'flex' }}>
                            <img src={imagPath} width='144' height='144' style={{borderRadius: 12}}/>
                            <div style={{ marginLeft: 24,width: 300 }}>
                                {/*<div>洗 &nbsp;消 &nbsp;点：<span>{imgFace.washSpotName ? imgFace.washSpotName : '无'}</span></div>*/}
                                <div>姓&emsp;&emsp;名：<span style={colorBlack}>{imgFace.name ? imgFace.name : '无'}</span></div>
                                <div style={marginTop10}>位&emsp;&emsp;置：<span style={colorBlack}>{imgFace.videoName ? (imgFace.workflowId == -1 ? extractWork + ' ' + imgFace.videoName : imgFace.videoName) : imgFace.workflowName}</span></div>
                                <div style={marginTop10}>操作时间：<span style={colorBlack}>{imgFace.createDate ? moment(imgFace.createDate).format("YYYY-MM-DD HH:mm:ss") : '无'}</span></div>
                                <div style={marginTop10}>岗&emsp;&emsp;位：<span style={colorBlack}>{imgFace.post ? imgFace.post : '无'}</span></div>
                                <p style={{marginTop: 10,float: 'left'}}>洗 &nbsp;消 &nbsp;点：</p><p style={{marginTop: 10,color: '  rgba(0, 0, 0, 0.85)'}}>{imgFace.washSpotName}</p>
                            </div>
                        </div>:
                        <div style={{ display: 'flex' }}>
                            <img src={imagPath} width='204' height='204' style={{borderRadius: 12}}/>
                            <div style={{ marginLeft: 24,width: 300 }}>
                                <div>车 &nbsp;牌 &nbsp;号：<span style={colorBlack}>{imgVehicle.name}</span></div>
                                {/*<div style={marginTop10}>监 &nbsp;管 &nbsp;人：<span style={colorBlack}>{imgVehicle.supervisor}</span></div>*/}
                                <div style={marginTop10}>位&emsp;&emsp;置：<span style={colorBlack}>{imgVehicle.videoName ? (imgVehicle.workflowId == -1 ? extractWork + ' ' + imgVehicle.videoName : imgVehicle.videoName) : imgVehicle.workflowName}</span></div>
                                <div style={marginTop10}>类&emsp;&emsp;型：<span style={colorBlack}>{imgVehicle.type === '1' ? '常驻车辆' :  '临时车辆'}</span></div>
                                {/*<div style={marginTop10}>车辆类型：<span style={colorBlack}>{*/}
                                {/*    carTypeData.map((item,index1) => {*/}
                                {/*        if(imgVehicle.carType === item.dictValue) {*/}
                                {/*            return  <span>{item.dictDesc}</span>*/}
                                {/*        }*/}
                                {/*    })}</span></div>*/}
                                <div style={marginTop10}>操作时间：<span style={colorBlack}>{moment(imgVehicle.createDate).format("YYYY-MM-DD HH:mm:ss")}</span></div>
                                <p style={{marginTop: 10,float: 'left'}}>洗 &nbsp;消 &nbsp;点：</p><p style={{marginTop: 10,color: 'rgba(0, 0, 0, 0.85)'}}>{imgVehicle.washSpotName}</p>
                            </div>
                        </div>
                }
                <div style={CutOff}></div>
                <div style={{marginTop: 10,display: 'flex'}}>
                    <div style={{fontSize: 16,fontWeight: '500',color: 'rgba(0,0,0,0.8)',marginTop: 5}}>行为路径</div>
                    <div style={{display: 'flex'}}>
                        <div>
                            <Button type="primary" style={{ marginLeft: 20,marginTop: 3 }} onClick={upDayClick}>上一天</Button>
                        </div>
                        <Form>
                            <Form.Item>
                                {getFieldDecorator('time')(
                                    <DatePicker style={{marginLeft: 20}} allowClear={false} onChange={dateChange} format="YYYY-MM-DD"/>
                                )}
                            </Form.Item>
                        </Form>
                    </div>
                    <div>
                        <Button type="primary" disabled={nextDayDate} style={{ marginLeft: 20,marginTop: 3 }} onClick={nextDayClick}>下一天</Button>
                    </div>
                </div>
                {
                    washDetailList.length === 0?<div style={{height: 50,textAlign: 'center',marginTop: 20,lineHeight: '40px'}}>暂无数据</div>:
                        <div>
                            <div style={{height: 300,overflow: 'auto',marginTop: 20}}>
                                <Steps progressDot current={stepCurrent} direction="vertical">
                                    {
                                        washDetailList.map((item,index) =>{
                                            return <Step title={
                                                index === stepCurrent?
                                                    <div style={{color: '#5A8BFF'}}>
                                                        <span>{moment(item.createDate).format("MM-DD HH:mm")}</span>
                                                        <span style={{marginLeft: 10,fontWeight: 500}}>{item.videoName}</span>
                                                        <span style={{fontSize: '12px',padding: 5,backgroundColor: 'rgba(90,139,255,0.2)',marginLeft: 10}}>记录点</span>
                                                    </div>:
                                                    <div>
                                                        <span>{moment(item.createDate).format("MM-DD HH:mm")}</span>
                                                        <span style={{marginLeft: 10,fontWeight: 500}}>{item.videoName}</span>
                                                    </div>
                                            } description={item.time} />
                                        })
                                    }
                                </Steps>
                            </div>
                        </div>
                }
            </Modal>
        </Container>
    )
}

export default Form.create()(WashTotal)