import React, { useState, useEffect } from 'react'
import {Container} from './style'

import { Button, Input, Modal, Steps, Popconfirm, Col, Form, message, Icon, Upload, Select, DatePicker } from 'antd'

import moment from 'moment'

import StoreSettingHttp from '../../../request/peopleWash'

import DeTable from '../../../component/DeTable';
import {stringFormat,getPeriodTime} from "../../../utils/homeFormat";
import CommonHttp from '../../../request/common-request'
import CommonRequest from "../../../request/common-request";
import WashTotalRequest from "../../../request/washTotal-request";

const _request = new StoreSettingHttp()
const _common = new CommonRequest()
const _washRequest = new WashTotalRequest()
const { MonthPicker, RangePicker } = DatePicker;

const marginTop10 = {marginTop: 10}
const colorBlack = {color: 'rgba(0, 0, 0, 0.9)'}
const CutOff = {borderTop: '1px solid #E5E6EA', width: 520, marginLeft: -24, marginTop: 24}
const {Option} = Select;
const { Step } = Steps;
function PeopleWash(props) {
    const [name, setName] = useState('')
	const [workflowId, setWorkflowId] = useState()
	const [imagePath, setImagePath] = useState(false) //图片查看
	const [imgFace, setImgFace] = useState('')
	const [imagPath, setImagPath] = useState('')
	const [extractWork, setExtractWork] = useState('')
    const [startTime, setStartTime] = useState('')
	const [endTime, setEndTime] = useState('')
	const [stepCurrent, setStepCurrent] = useState(0)
    const tableColunm =
        [
            {
                title: '序号',
                key: 'number',
                render: (text, record, index) => (
                    <span>{index + 1}</span>
                )
            },
			{
			    title: '洗消点',
			    dataIndex: 'washSpotName',
			    key: 'washSpotName',
				render: (text,record,index) => (
					stringFormat(text,9)
				)
			},
            {
                title: '姓名',
                dataIndex: 'name',
                key: 'name',
            },
			{
			    title: '流程',
			    dataIndex: 'workflowName',
			    key: 'workflowName',
			},
//          {
//              title: '所属猪场',
//              key: 'pigFramName',
//              dataIndex: 'pigFramName',
//          },
            {
                title: '视频位置',
                key: 'videoName',
                dataIndex: 'videoName',
            },
			{
			    title: '操作时间',
			    key: 'createDate',
			    dataIndex: 'createDate',
				render: (text, record, index) => (
				    <span>{moment(text).format("YYYY-MM-DD HH:mm:ss")}</span>
				)
			},
			{
			    title: '岗位',
			    key: 'post',
			    dataIndex: 'post',
			},
			{
				title: '操作',
				key: 'imgPath',
				dataIndex: 'imgPath',
				render: (imgPath, record) => (
					<span className='tableA' onClick={imagesPath.bind(this, imgPath, record)}>详情</span>
				)
			},
		]
		
	//查看图片
	const imagesPath = (imgPath,record) => {
		peopleWashDetail(record)
		setImgFace(record)
		setImagePath(true)
		setImagPath(imgPath)
		const extractWork = record.workflowName
		setExtractWork(extractWork.substring(0,4))
	}
     //表格结构
    const [tableTotal, setTableTotal] = useState(0)
    const [tableData, setTableData] = useState([]) //表格数据
	const [washList, setWashList] = useState([])
	const [washDetailList, setWashDetailList] = useState([])
	const {washSpotId,time} = props
    useEffect(() => {
		washSpotList()
		if (washSpotId){
			setWorkflowId(washSpotId.split('&')[1])
			let endDate = moment(time).format('YYYY-MM-DD ') + '23:59:59'
			setStartTime(moment(time).format('YYYY-MM-DD HH:mm:ss'))
			setEndTime(endDate)
			getWashList('',1,10,washSpotId,time)
		}else {
			getWashList()
		}
    }, [washSpotId,time])
    //获取员工列表
    const getWashList = (ButtonType, pageNo = 1, pageSize = 10,id,date) => {
        let params = {
            name,
			washSpotId:workflowId,
            startTime:startTime,
            endTime: endTime,
            pageNo,
            pageSize
        }
		if (id){
			params.washSpotId = id.split('&')[1]
			let endDate = moment(date).format('YYYY-MM-DD ') + '23:59:59'
			params.startTime = moment(date).format('YYYY-MM-DD HH:mm:ss')
			params.endTime = endDate
		}
		// if (washSpotId){
		// 	params.washSpotId = washSpotId.split('&')[1]
		// 	let endDate = moment(time).format('YYYY-MM-DD ') + '23:59:59'
		// 	params.startTime = moment(time).format('YYYY-MM-DD HH:mm:ss')
		// 	params.endTime = endDate
		// }
        if (ButtonType === 'reset') {
            params = {
            	pageNo,
				pageSize
			}
        }
        _request.getWashList(params).then(res => {
            setTableTotal(res.total || 0)
            setTableData(res.list)
        })
    }

    //搜索
    const clickSearch = () => {
        getWashList()
    }
	
    //重置
    const clickReset = () => {
		setName('')
		setWorkflowId()
		setStartTime('')
		setEndTime('')
        getWashList('reset',1,10)
    }

	const washSpotList = () => {
		let params = {}
		_common.getWashSpotList(params).then(res => {
			setWashList(res)
		})
	}

	const peopleWashDetail = (val) =>{
		let param = {
			id: val.personId,
			beginDate: moment(val.createDate).format("YYYY-MM-DD")
		}
		_washRequest.peopleWashDetail(param).then(res =>{
			let arr = []
			let list = deWeightThree(res.list)
			list.map((item,index,list) => {
				item.time = index > 0 ? getPeriodTime(item,list[index-1]) : '';
				arr.splice(0,0,item)
			})
			arr.forEach((item,index) =>{
				if (item.createDate === val.createDate){
					setStepCurrent(index)
				}
			})
			setWashDetailList(arr)
		})
	}
	const deWeightThree = (arr) => {
		let map = new Map();
		for (let item of arr) {
			if (!map.has(moment(item.createDate).format("YYYY-MM-DD HH:mm"))) {
				map.set(moment(item.createDate).format("YYYY-MM-DD HH:mm"), item);
			}
		}
		return [...map.values()];
	}
    //日期控件搜索
    function range(start, end) {
	  const result = [];
	  for (let i = start; i < end; i++) {
	    result.push(i);
	  }
	  return result;
	}
	
	function disabledDate(current) {
	  // Can not select days before today and today
	  return current && current < moment().endOf('day');
	}


    function disabledDateTime() {
	  return {
	    disabledHours: () => range(0, 24).splice(4, 20),
	    disabledMinutes: () => range(30, 60),
	    disabledSeconds: () => [55, 56],
	  };
	}
	
	function disabledRangeTime(_, type) {
	  if (type === 'start') {
	    return {
	      disabledHours: () => range(0, 60).splice(4, 20),
	      disabledMinutes: () => range(30, 60),
	      disabledSeconds: () => [55, 56],
	    };
	  }
	  return {
	    disabledHours: () => range(0, 60).splice(20, 4),
	    disabledMinutes: () => range(0, 31),
	    disabledSeconds: () => [55, 56],
	  };
	}
	
    function rangeTimeChange(date){
    	if(date){
    		if(date[0]!=undefined){
    			setStartTime(date[0].format('YYYY-MM-DD HH:mm:ss'));
    		}else{
    			setStartTime('');
    		}
    		if(date[1]!=undefined){
    			setEndTime(date[1].format('YYYY-MM-DD HH:mm:ss'));
    		}else{
    			setEndTime('');
    		}
    	}
    }
	
	const imageEdit = () =>{
        setImagePath(false)
    }

    return (
        <Container>
            <div className="top">
				<Select placeholder="请选择洗消点" style={{width: 200, marginRight: 10}} showSearch value={workflowId}
						onChange={e => setWorkflowId(e)}
						filterOption={(input, option) =>
							option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}>
					{
						washList.map((item, index) => {
							return <Option key={item.key} value={item.id}>{item.name}</Option>
						})
					}
				</Select>
			    {/*<Input placeholder="请输入洗消点名称" style={{ width: 200, marginRight: 10 }} value={washName} onPressEnter={clickSearch} onChange={(e) => setWashName(e.target.value)}></Input>*/}
                <Input placeholder="请输入姓名" style={{ width: 200, marginRight: 10 }} value={name} onPressEnter={clickSearch} onChange={(e) => setName(e.target.value)}></Input>
                <RangePicker
                	ranges={{
				        '今天': [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
				        '一个月': [moment().startOf('month'), moment().endOf('month')],
				      }}
	                value={startTime===''||endTime===''?null:[moment(startTime, "YYYY-MM-DD HH:mm:ss"), moment(endTime, "YYYY-MM-DD HH:mm:ss")]}
				    /*disabledTime={disabledRangeTime}*/
				    onChange={rangeTimeChange}
				    showTime={{
				    	hideDisabledOptions: true,
				        defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
				    }}
				    format="YYYY-MM-DD"
				/>
	                
               	&nbsp;&nbsp;
                <Button type="primary" style={{ marginRight: 10 }} onClick={clickSearch}><span className='iconfont icon-sousuo buttonIcon'></span>搜索</Button>
                <Button type="primary" style={{ marginRight: 10 }} className='buttonReset' onClick={clickReset}><span style={{fontSize: 13}} className='iconfont icon-zhongzhi buttonIcon'></span>重置</Button>
            </div>
			<div style={{marginBottom: 5}}>合计&nbsp;{tableTotal}&nbsp;条</div>
            <div className="bottom">
            	<DeTable columns={tableColunm} total={tableTotal} onPaginationChange={(pageNo, pageSize) => getWashList('', pageNo, pageSize)}  dataSource={tableData} />
            </div>
			<Modal
                title={'人员详情'}
                visible={imagePath}
                centered={true}
                width={'520px'}
                onCancel={imageEdit}
                maskClosable={false}
                destroyOnClose={true}
                footer={null}
            >

				<div style={{ display: 'flex' }}>
					<img src={imagPath} width='144' height='144' style={{borderRadius: 12}}/>
					<div style={{ marginLeft: 24,width: 300 }}>
						{/*<div>洗 &nbsp;消 &nbsp;点：<span>{imgFace.washSpotName ? imgFace.washSpotName : '无'}</span></div>*/}
						<div>姓&emsp;&emsp;名：<span style={colorBlack}>{imgFace.name ? imgFace.name : '无'}</span></div>
						<div style={marginTop10}>位&emsp;&emsp;置：<span style={colorBlack}>{imgFace.videoName ? (imgFace.workflowId == -1 ? extractWork + ' ' + imgFace.videoName : imgFace.videoName) : imgFace.workflowName}</span></div>
						<div style={marginTop10}>操作时间：<span style={colorBlack}>{imgFace.createDate ? moment(imgFace.createDate).format("YYYY-MM-DD HH:mm:ss") : '无'}</span></div>
						<div style={marginTop10}>岗&emsp;&emsp;位：<span style={colorBlack}>{imgFace.post ? imgFace.post : '无'}</span></div>
						<p style={{marginTop: 10,float: 'left'}}>洗 &nbsp;消 &nbsp;点：</p><p style={{marginTop: 10,color: '  rgba(0, 0, 0, 0.85)'}}>{imgFace.washSpotName}</p>
					</div>
				</div>
				<div style={CutOff}></div>
				<div style={{fontSize: 16,fontWeight: '500',marginTop: 10,color: 'rgba(0,0,0,0.8)'}}>行为路径</div>
				{
					washDetailList.length === 0?
						<div style={{height: 50,textAlign: 'center',marginTop: 20,lineHeight: '40px'}}>暂无数据</div>:
						<div>
							<div style={{height: 300,overflow: 'auto',marginTop: 20}}>
								<Steps progressDot current={stepCurrent} direction="vertical">
									{
										washDetailList.map((item,index,list) =>{
											return <Step title={
												index === stepCurrent?
												<div style={{color: '#5A8BFF'}}>
													<span>{moment(item.createDate).format("MM-DD HH:mm")}</span>
													<span style={{marginLeft: 10,fontWeight: 500}}>{item.videoName}</span>
													<span style={{fontSize: '12px',padding: 5,backgroundColor: 'rgba(90,139,255,0.2)',marginLeft: 10}}>记录点</span>
												</div>:
												<div>
													<span>{moment(item.createDate).format("MM-DD HH:mm")}</span>
													<span style={{marginLeft: 10,fontWeight: 500}}>{item.videoName}</span>
												</div>
											} description={item.time} />
										})
									}

								</Steps>
							</div>
						</div>
				}
                {/*{*/}
                {/*    imagPath ?*/}
				{/*	<div style={qrStyle}>*/}
				{/*		<div style={centerStyle}><img src={imagPath} alt="" style={{ width: '100%' }} /></div>*/}
				{/*		<div style={{ marginTop: 10 }}>*/}
				{/*			<p>洗消点：{ imgFace.washSpotName ? imgFace.washSpotName : '无' }</p>*/}
				{/*			<p>姓名：{ imgFace.name ? imgFace.name : '无' }</p>*/}
				{/*			<p>位置：{ imgFace.videoName ? (imgFace.workflowId == -1 ? extractWork + ' ' + imgFace.videoName : imgFace.videoName) : imgFace.workflowName }</p>*/}
				{/*			<p>操作时间：{ imgFace.createDate ? moment(imgFace.createDate).format("YYYY-MM-DD HH:mm:ss") : '无' }</p>*/}
				{/*			<p>岗位：{ imgFace.post ? imgFace.post : '无' }</p>*/}
				{/*		</div>*/}
				{/*	</div>*/}
				{/*	:*/}
                {/*    <div style={ qrStyle }>暂无图片</div>*/}
				{/*}*/}
            </Modal>
        </Container>

    )
}

export default Form.create()(PeopleWash)