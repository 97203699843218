import React from 'react'
import PureRenderMixin from 'react-addons-pure-render-mixin'
import { Row, Col, Tree, Divider, Button, Input, message, Form, Select, Table, Modal } from 'antd'

import SystemRequest from '../../../request/system-request'
import PowerButton from '../../../component/PowerButton'
import ModalSource from './subpage/index'
import moment from 'moment'
import {Container} from './style'
import DeTable from '../../../component/DeTable/index'

import './style.css'


const TreeNode = Tree.TreeNode;
const sys_request = new SystemRequest();
const Option = Select.Option;
const FormItem = Form.Item;

class UserForm extends React.Component {
	constructor(props) {
		super(props);
		this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
		this.state = {
			currentPage: 1,
			organlist: [],
			tdata: [],
			manage: '',
			table: {
				pageSize: 10,
				pageNo: 1,
				total: 0
			},
			enterpriseType: null,
			regionId: null,
			regionName: null,
			// tcolumn: [],
			showTable: false,
			isShow: false,
			isShow2: false,
			modaltitle: '',
			tid: '',
			pid: '',
			type: 'add',
			selectedNodes: null,
			organizechoosedata: { userState: 1 },
			searchname: '',
			usertypelist: [],//用户类型字典
			urlpid: '',
			tcolumn: [{
				title: '序号',
				render: (text, record, index) => (
					<span>{index + 1}</span>
				),
				dataIndex: 'number',
			},{
				title: '用户名称',
				dataIndex: 'enterpriseName'
			}, {
				title: '用户账号',
				dataIndex: 'account'
			}, {
				title: '用户类型',
				dataIndex: 'userType'
			}, {
				title: '用户状态',
				dataIndex: 'userState',
				render: (text, record, index) => (
					<span>
						{text == 0 ? <span>无效</span> : <span>有效</span>}
					</span>
				)
			}, {
				title: '账号有效期',
				dataIndex: 'benginDate',
				render: (text, record, index) => {
					return record.userState == 0 ? "" : <span>{moment(record.benginDate).format('YYYY-MM-DD')}至{moment(record.endDate).format('YYYY-MM-DD')}</span>;
				}
			}, {
				title: '联系人',
				dataIndex: 'linkName'
			}, {
				title: '联系电话',
				dataIndex: 'linkPohone'
			}, {
				title: '操作时间',
				dataIndex: 'createDate',
				render: (text, record, index) => (
					<span>{moment(text).format('YYYY-MM-DD')}</span>
				)
			}, {
				title: '操作',
				width: 140,
				render: (text, record, index) => (<span><PowerButton
					pageId={this.state.urlpid}
					btnType='update'
					size="small"
					onClick={this.singleEdit.bind(this, record, index)}
					title='编辑' >
				</PowerButton>
					<Divider type="vertical" />
					<PowerButton
						pageId={this.state.urlpid}
						btnType='delete'
						size="small"
						type="danger"
						onClick={this.singleDel.bind(this, record, index)}
						title='删除' >
					</PowerButton></span>)
			}]
		}
	}
	componentWillMount() {
		this.setState({ urlpid: this.props.pageId })
		//获取字典    
		// sys_request.getSysType({
		// 	dictType: 'USER_TYPE'
		// }).then((res) => {
		// 	this.setState({
		// 		usertypelist: res
		// 	})
		// })
		sys_request.organizeList({
			parentId: ''
		}).then((res) => {
			this.setState({
				organlist: res,
				regionId: res[0].regionId,
				regionName: res[0].regionDesc,
				regionType: res[0].regionType
			}, () => {
				this.onSelect([this.state.organlist[0].regionId])
			})
		})
	}
	onLoadData(treeNode) {
		let id = treeNode.props.eventKey;
		return new Promise((resolve) => {
			if (treeNode.props.children) {
				resolve();
				return;
			}
			setTimeout(() => {
				sys_request.organizeList({
					parentId: id
				}).then((res) => {
					if (res) {
						let childrentmp = [];
						for (var i = 0; i < res.length; i++) {
							childrentmp.push({ regionName: res[i].regionName, regionId: res[i].regionId, enterpriseType: res[i].enterpriseType })
						}
						treeNode.props.dataRef.children = childrentmp;
						this.setState({
							organlist: [...this.state.organlist],
						});
						resolve();
					}

				})

			}, 500);
		});

	}
	renderTreeNodes(data) {
		return data.map((item) => {
			if (item.children) {
				return (
					<TreeNode title={item.regionName} key={item.regionId} dataRef={item}>
						{this.renderTreeNodes(item.children)}
					</TreeNode>
				);
			}
			return <TreeNode title={item.regionName} key={item.regionId} dataRef={item} />;
		});
	}
	onSelect(selectedKeys, info) {
		if (info) {
			this.setState({
				selectedNodes: info.selectedNodes[0].props.dataRef
			})
		}
		if (selectedKeys != '' && selectedKeys.length > 0) {
			//console.log(selectedKeys.constructor==Array)
			this.setState({
				showTable: true,
				pid: selectedKeys[0],
				tdata: [],
				regionName: info ? info.node.props.dataRef.regionName : this.state.regionName,
				enterpriseType: info ? info.node.props.dataRef.enterpriseType : this.state.enterpriseType
			})
			var formData = this.props.form;
			sys_request.userList({
				pageSize: this.state.table.pageSize,
				pageNo: this.state.table.pageNo,
				regionId: selectedKeys[0],
				userType: formData.getFieldValue('userType'),
				account: formData.getFieldValue('account'),
				linkPohone: formData.getFieldValue('linkPohone')
			}).then((res) => {
				if (!res) return;
				this.setState({
					table: {
						total: res.total
					},
					tdata: res.list,
					currentPage: 1
				})
			})
		}

	}
	singleEdit(record, index) {
		sys_request.sysUserDetail({ id: record.userId }).then((res) => {
			this.setState({
				isShow: true,
				tid: res.userId,
				// regionId: res.regionId,
				// type: 'edit',
				// modaltitle: '编辑用户',
				// organizechoosedata: res
			})
			setTimeout(()=>{
				this.setState({
					manage: res.manage
				})
			},0)
			console.log(res.manage)
		})

	}

	singleDel(record, index) {
		sys_request.deleteSysUser({ id: record.userId }).then((res) => {
			const dataSource = [...this.state.tdata];
			dataSource.splice(index, 1);//index为获取的索引，后面的 1 是删除几行  
			this.setState({ tdata: dataSource });
		})

	}
	setIsShow(callback) {
		this.setState({
			isShow: callback
		})
	}
	setValues(callback) {
		var openId = callback.openId;
		if (this.state.type === 'add') {
			sys_request.addSaveUser(JSON.stringify({
				account: callback.account,
				address: callback.address,
				endDate: callback.endDate,
				benginDate: callback.beginDate,
				enterpriseName: callback.enterpriseName,
				linkName: callback.linkName,
				linkPohone: callback.linkPohone,
				nikeName: callback.nikeName,
				roleIdList: callback.roleIdList,
				userState: callback.userState,
				password: callback.password,
				manage: callback.manage,
				regionId: this.state.tid,
				openId
			})).then((res) => {
				this.onSelect([this.state.pid])
				let isShow = false;
				this.setIsShow(isShow);
				message.success('新增用户保存成功');
			})
		} else {
			sys_request.editSaveUser(JSON.stringify({
				account: callback.account,
				address: callback.address,
				endDate: callback.endDate,
				benginDate: callback.beginDate,
				enterpriseName: callback.enterpriseName,
				linkName: callback.linkName,
				linkPohone: callback.linkPohone,
				nikeName: callback.nikeName,
				roleIdList: callback.roleIdList,
				userState: callback.userState,
				password: callback.password,
				manage: callback.manage,
				userId: this.state.tid,
				regionId: this.state.regionId,
				openId
			})).then((res) => {
				this.setIsShow(false);
				this.onSelect([this.state.pid])
				message.success('编辑用户保存成功');
			})
		}

	}
	addNewModal() {
		if (!this.state.selectedNodes) return message.error("请选择上级用户!");
		this.setState({
			isShow: true,
			tid: this.state.pid,
			type: 'add',
			modaltitle: '新增用户',
			organizechoosedata: { userState: 1 }
		})
	}
	searchOranize(value) {
		this.setState({
			searchname: value
		}, () => {
			this.onSelect([this.state.pid])
		})
	}
	//搜索按钮事件
	handleSubmitSearch(e) {
		e.preventDefault();
		this.props.form.validateFieldsAndScroll((err, values) => {
			if (err) {
				return;
			}

			this.setState({
				table: {
					account: values.account,
					inkPohone: values.inkPohone,
					userType: values.dictValue
				}
			}, () => {
				this.onSelect([this.state.pid])
			})

		});
	}
	editConFire(){
		sys_request.editSaveUser(JSON.stringify({
			manage: this.state.manage,
			userId: this.state.tid,
		})).then((res) => {
			this.setIsShow(false);
			this.onSelect([this.state.pid])
			message.success('编辑用户保存成功');
		})
	}
	onSelectChangeManage(e){
		this.setState({
			manage: e
		})
	}
	//清空条件
	clearCondition() {
		this.props.form.resetFields();
		this.setState({
			table: {
				account: '',
				inkPohone: '',
				userType: ''
			}
		}, () => {
			this.onSelect([this.state.pid])
		});
	}
	render() {
		const { getFieldDecorator } = this.props.form;
		// let usertypelist = [];
		// for (let i = 0; i < this.state.usertypelist.length; i++) {
		// 	usertypelist.push(<Option key={i + 1} value={this.state.usertypelist[i].dictValue}>{this.state.usertypelist[i].dictDesc}</Option>)
		// }
		return (
			<Container>
				<Row gutter={16} style={{ height: '100%' }}>
					<Col span={4} style={{ borderRight: '1px solid #ddd', height: '100%', overflowX: 'auto' }}>
						<Tree loadData={this.onLoadData.bind(this)} showLine onSelect={this.onSelect.bind(this)} style={{ height: '100%' }}>
							{this.renderTreeNodes(this.state.organlist)}
						</Tree>
					</Col>
					<Col span={20} style={{ height: '100%' }}>
						{
							this.state.showTable
								?
								<React.Fragment>
									<div style={{ margin: '0 0 10px 0' }}>
										<Form layout="inline" onSubmit={this.handleSubmitSearch.bind(this)}>
											<span style={{ marginRight: "5px", position: "relative", top: "4px" }}>
												<PowerButton
													pageId={this.state.urlpid}
													btnType='add'
													onClick={this.addNewModal.bind(this)}
													title='新增'>
												</PowerButton>
											</span>

											<FormItem>
												{getFieldDecorator('account', {
													initialValue: this.state.table.account,
												})(
													<Input placeholder="请输入用户账号" />
												)}
											</FormItem>
											<FormItem>
												{getFieldDecorator('linkPohone')(
													<Input placeholder="请输入联系电话" />
												)}
											</FormItem>
											{/*<FormItem>*/}
											{/*	{getFieldDecorator('userType')(*/}
											{/*		<Select style={{ width: 160 }} placeholder="请选择用户类型" >*/}
											{/*			{usertypelist}*/}
											{/*		</Select>*/}
											{/*	)}*/}
											{/*</FormItem>*/}
											<FormItem>
												<Button
													type="primary"
													htmlType="submit"
												>
													查询
					          </Button>
											</FormItem>
											<FormItem>
												<Button
													type="primary"
													onClick={this.clearCondition.bind(this)}
												>
													清空
					          					</Button>
											</FormItem>
										</Form>
									</div>
									<div className="bottom">
										<DeTable dataSource={this.state.tdata} columns={this.state.tcolumn} pagination={{ current: this.state.currentPage, onChange: (page) => this.setState({ currentPage: page }), showSizeChanger: true, showTotal: (total) => `共 ${total} 条` }}></DeTable>
									</div>
								</React.Fragment>
								:
								''
						}

					</Col>
				</Row>
				<Modal
					title='设置洗消管理员'
					visible={this.state.isShow}
					onCancel={this.setIsShow.bind(this,false)}
					footer={
						<div style={{width: '100%', textAlign: 'center'}}>
							<Button type="primary" style={{width: 200}} onClick={this.editConFire.bind(this)}>提交</Button>
						</div>
					}
				>
					<Form className="ant-advanced-search-form">
						<FormItem label="是否洗消管理员">
							{getFieldDecorator('manage',{
								initialValue: this.state.manage
							})(
								<Select onChange={this.onSelectChangeManage.bind(this)}>
									<Option value="1">是</Option>
									<Option value="0">否</Option>
								</Select>
							)}

						</FormItem>
					</Form>
				</Modal>
				{/*<ModalSource*/}
				{/*	type={this.state.type}*/}
				{/*	organizechoosedata={this.state.organizechoosedata}*/}
				{/*	isShow={this.state.isShow}*/}
				{/*	setValues={this.setValues.bind(this)}*/}
				{/*	usertypelist={this.state.usertypelist}*/}
				{/*	setIsShow={this.setIsShow.bind(this)}*/}
				{/*	modaltitle={this.state.modaltitle}*/}
				{/*	regionId={this.state.pid}*/}
				{/*	regionName={this.state.regionName}*/}
				{/*	enterpriseType={this.state.enterpriseType}/>*/}
			</Container>
		)
	}
}
const User = Form.create()(UserForm);
export default User;