import axios from 'axios'
import qs from 'qs'
import {message} from 'antd'
import other from '../src/request/other-request'
const _request = new other()
let isHttps = 'https:' == document.location.protocol ? 'https://' : 'http://'
// hmy：http://172.16.4.37:8089
// http://test.rnpig.com/
// axios.defaults.baseURL = 'http://safety.rnpig.com/'
// axios.defaults.baseURL = 'https://bio.rnpig.com/api/'//新版域名
// axios.defaults.baseURL = 'http://192.168.200.13:8081/api/'//开发环境
// axios.defaults.baseURL = 'http://api.hfmygnqszcyh.com/api/'//开发环境
// axios.defaults.baseURL = isHttps+'api.rnpig.com/api/'//开发环境
axios.defaults.baseURL = isHttps+'api.hsfxm.cn/api/'//开发环境
// axios.defaults.baseURL = 'http://47.106.182.226:9088/'//测试环境
// axios.defaults.baseURL = 'http://192.168.200.122:9011/'
axios.defaults.withCredentials = true

axios.interceptors.request.use(function (config) {
    // 判断请求的类型
    // 如果是post请求就把默认参数拼到data里面
    // 如果是get请求就拼到params里面
    if ((config.method === 'post' || config.method === 'put') && config.headers["Content-Type"] === 'application/x-www-form-urlencoded') {
        let data = qs.parse(config.data)
        config.params = {
            ...config.params
        }
        config.data = qs.stringify({
            ...data
        }, {
            arrayFormat: 'brackets'
        })
    }
    return config
}, function (error) {
    return Promise.reject(error)
})

axios.interceptors.response.use((res) => {
    //对响应数据做些事
    // if (res.config.method === 'get') {
    //     return res
    // }
    if (res.data.code === '0000') {
        // if (res.config.method === 'post') {
        return res.data.data
        // }
    }else if (res.data.code === 200){
        //umps
        return res.data.result
    } else if (res.data.code === "304") {
        // message.warn('2秒后将重新登录……')
        // axios.post('client/sys/getsysInfo/getLoginUrl').then(function (res) {
        //     localStorage.removeItem('token')
        //     localStorage.removeItem('regionId')
        //     localStorage.removeItem('user')
        //     window.location.href = res.sysLoginPath
        // })
        _request.getLoginUrl().then(res => {
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('regionId')
            sessionStorage.removeItem('user')
            window.location.href=res.sysLoginPath
        })
        // window.location.href = '/#/login'
    } else {
        return Promise.reject(res.data.message)
    }
}, (error) => {
    if (error.response.status === 401){
        _request.getLoginUrl().then(res => {
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('regionId')
            sessionStorage.removeItem('user')
            window.location.href=res.sysLoginPath
        })
        return
    }
    return Promise.reject('请求异常')
    // return 1
})

function request(params){
    return new Promise((resolve, reject) => {
        axios({
            url: params.url || '',
            method: params.method || 'post',
            params: params.params || {},
            data: params.data || {},
            headers: {
                'Content-Type': params.contentType || 'application/x-www-form-urlencoded',
                sessionId: getSessionId(),
                'x-auth-token': sessionStorage.getItem('token'),
                regionId: sessionStorage.getItem('regionId'),
            },
            paramsSerializer: function (param) {
                return qs.stringify(param, {
                    arrayFormat: 'repeat'
                })
            },
        }).then(res => {
            resolve(res)
        }).catch(err => {
            message.error(err || '出问题')
            reject(err)
        })
    })
}
function getSessionId(){
    let sessionId = '', paramsStr = window.location.href.split('?')[1];
    if(paramsStr){
      let paramsObj = {};
      paramsStr.replace(/&amp;/g, '&').split('&').forEach(item => {
        let param = item.split('=');
        paramsObj[param[0]] = param[1];
      })
      sessionId = paramsObj.sessionId;
    }
    return sessionId;
}
export default request
