import React, {useState, useEffect} from 'react'
import {Container} from './style'
import { connect } from 'react-redux'

import {
    Button,
    Input,
    Modal,
    Table,
    TreeSelect,
    Popconfirm,
    Col,
    Form,
    message,
    Icon,
    Upload,
    Select,
    DatePicker
} from 'antd'

import StoreSettingHttp from '../../../../request/visitor';
import CommonHttp from '../../../../request/common-request'
import AuditRequest from "../../../../request/audit-request";

import DeTable from '../../../../component/DeTable';

import moment from 'moment';
import {InfoCircleOutlined} from "@ant-design/icons";

const _request = new StoreSettingHttp();
const _AuditRequest = new AuditRequest();
const _commonrequest = new CommonHttp();
const {TextArea} = Input;
const headers = {
    'x-auth-token': sessionStorage.getItem('token')
}
function beforeUpload(file) {
    if (file.type.indexOf("image") == -1) return message.error('上传文件类型为图片!');
    const isLt2M = file.size / 1024 / 1024 < 3;
    if (!isLt2M) {
        message.error('图片太大了，要小于3000kb!');
    }
    return isLt2M;
}
const pigFarmDiv = {
    padding: '9px 15px 10px 15px',
    marginRight: 10,
    background: '#F2F7FC',
    borderRadius: 5,
    color: 'rgba(0, 0, 0, 0.45)',
    fontSize: 13
}
const CutOff = {borderTop: '1px solid #E5E6EA', width: 500, marginLeft: -24, marginTop: 24}
const marginTop10 = {marginTop: 10}
const marginTop20 = {marginTop: 22}
const titleP = {
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.85)'
}

function Visitor(props) {
    const {baseURL} = props
    const [editVisible, setEditVisible] = useState(false) //编辑项
    const [auditVisible, setAuditVisible] = useState(false) //审核
    const [auditData, setAuditData] = useState({}) //审核数据
    const [confirmLoading, setConfirmLoading] = useState(false) //编辑模态框加载图标
    const [imageUrl, setImageUrl] = useState('')
    const [imageId, setImageId] = useState('')
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [status, setStatus] = useState('3')
    const [pigFarmList, setPigFarmList] = useState([])
    const [addPigFarmList, setAddPigFarmList] = useState([])
    const [expandTreeList, setExpandTreeList] = useState([])
    const [auditType, setAuditType] = useState('')
    const [isDisabled, setIsDisabled] = useState(false)
    const statusData = [
        {
            id: '3',
            name: '未审核'
        },
        {
            id: '',
            name: '全部'
        },
        {
            id: '1',
            name: '通过'
        },
        {
            id: '2',
            name: '未通过'
        },
    ]
    const tableColunm = [
        {
            title: '序号',
            key: 'number',
            render: (text, record, index) => (
                <span>{index + 1}</span>
            )
        },
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '手机号',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: '授权访问时间',
            key: 'authVisit',
            dataIndex: 'authVisit',
            render: (text, record, index) => (
                <span>{moment(text).format("YYYY-MM-DD")}</span>
            )
        },
        {
            title: '操作时间',
            key: 'createDate',
            dataIndex: 'createDate',
            render: (text, record, index) => (
                <span>{moment(text).format("YYYY-MM-DD HH:mm:ss")}</span>
            )
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record, index) => (
                <span>
                    <span className='tableA' style={{marginRight: 8}}
                          onClick={openAudit.bind(this, record, '123')}>详情</span>
                    {
                        record.state === '3' ?
                            <span className='tableA' style={{marginRight: 8}}
                                  onClick={openAudit.bind(this, record, 'audit')}>审批</span>
                            : ''
                    }
                </span>
            ),
        },
    ]
    //表格结构
    const [tableTotal, setTableTotal] = useState(0)
    const [tableData, setTableData] = useState([]) //表格数据
    const [userPostData, setUserPostData] = useState([]) //用户岗位列表
    const [personComments, setPersonComments] = useState('')
    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 8},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 14},
        },
    }
    const {getFieldDecorator} = props.form
    useEffect(() => {
        headers['x-auth-token'] =  sessionStorage.getItem('token')
        getVisitorList()
        getDictList()
        getPigFarm()
    }, [])
    //获取员工列表
    const getVisitorList = (ButtonType, pageNo = 1, pageSize = 10) => {
        let params = {
            name: name,
            phone: phone,
            state: status,
            pageNo,
            pageSize
        }
        if (ButtonType === 'reset') {
            params = {
                state: '3',
                pageNo,
                pageSize
            }
        }
        _AuditRequest.getVisitorList(params).then(res => {
            setTableTotal(res.total || 0)
            setTableData(res.list)
        })
    }

    //获取用户岗位列表
    const getDictList = () => {
        let params = {
            dictType: 'USER_POST'
        }
        _commonrequest.getDictList(params).then(res => {
            setUserPostData(res.list)
        })
    }
    const getPigFarm = () => {
        let params = {
            regionType: 5,
            enterpriseType: 2
        }
        _commonrequest.getFramBuilddingList(params).then(res => {
            let list = []
            res.forEach(item => {
                let childList = []
                item.buildList.forEach(child => {
                    childList.push({
                        title: child.buildingName,
                        value: child.buildingId,
                        isLeaf: true
                    })
                })
                list.push({
                    title: item.pigFramName,
                    value: item.pigFramId,
                    children: childList,
                    // selectable: false,
                    // disableCheckbox: true
                })
            })
            setAddPigFarmList(list)
        })
    }
    //打开审核页
    const openAudit = (record, type) => {
        const {setFieldsValue} = props.form
        setAuditType(type)
        if (type === 'audit') {
            setIsDisabled(false)
        } else {
            setPersonComments(record.comments)
            setIsDisabled(true)
        }
        setAuditVisible(true)
        setTimeout(() => {
            setAuditData({
                name: record.name,
                // credNo: record.credNo,
                phone: record.phone,
                credNo: record.credNo,
                registrant: record.registrant,
                id: record.id,
                post: record.post,
                time: moment(moment(record.authVisit).format("YYYY-MM-DD"), "YYYY-MM-DD")
            })
        }, 0)
        setImageUrl(record.imgPath)
        _commonrequest.getTempPreBuildding({
            tempPeopleId: record.id
        }).then(res => {
            let list = []
            let id
            res.forEach(item => {
                if (!item.split) return
                id = item.split('-')[0]
                if (!list.includes(id)) {
                    list.push(id)
                }
            })
            let listValue = []
            res.forEach(item => {
                listValue.push({
                    value: item.value,
                    label: item.label
                })
            })
            setExpandTreeList(list)
            setPigFarmList(listValue)
        })
    }
    //打开新增
    const openModal = () => {
        setEditVisible(true)
    }

    const {getFieldValue, validateFields, setFieldValue} = props.form

    //编辑页提交
    const editConfire = () => {
        validateFields((err, value) => {
            if (!err) {
                setConfirmLoading(true)
                let idList = getFieldValue('pigFramId').map(item => {
                    return item.value + "&" + item.label
                })
                let params = {
                    name: getFieldValue('name'),
                    pigFramIds: JSON.stringify(idList),
                    post: getFieldValue('post'),
                    imgPath: imageUrl,
                    imgPathId: imageId,
                    // credNo: getFieldValue('credNo'),
                    phone: getFieldValue('phone'),
                    authVisit: moment(getFieldValue('authVisit')).format("YYYY-MM-DD"),
                    source: 'web'
                }
                _request.addVisitor(params).then(res => {
                    setConfirmLoading(false)
                    message.info('新增成功！')
                    setEditVisible(false)
                    setImageUrl('')
                    getVisitorList()
                })
            }
            setConfirmLoading(false)
        })
    }
    //编辑页取消
    const cancelEdit = () => {
        setEditVisible(false)
        setConfirmLoading(false)
        setImageUrl('')
        setAuditVisible(false)
        setPersonComments('')
    }
    //审核
    const confirm = (e) => {
        let param = {
            state: e,
            id: auditData.id,
            comments: personComments,
        }
        if (e === '2') {
            if (personComments === '') return message.error('请输入驳回原因！')
        }
        setConfirmLoading(true)
        _AuditRequest.VisitorConfirm(param).then(res => {
            setConfirmLoading(false)
            setImageUrl('')
            setAuditVisible(false)
            getVisitorList()
            message.info('审批成功！')
        })
    }
    //搜索
    const clickSearch = () => {
        getVisitorList()
    }
    //重置
    const clickReset = () => {
        setName('')
        setPhone('')
        setStatus('3')
        getVisitorList('reset')
    }

    const handleChange = (info, e, data) => {
        if (info.file.status === 'done' && info.file.response && info.file.response.code == 200) {
            setImageUrl(info.file.response.result.url);
            setImageId(info.file.response.result.id);
        }
    }


    const {Option} = Select;

    return (
        <Container>
            <div className="top">
                <Button type="primary" style={{marginRight: 10,backgroundColor: '#689F38',borderColor: '#689F38'}} onClick={openModal.bind(this, 'add')}><span className='iconfont icon-tianjia buttonIcon'></span>新增访客</Button>
                <Select style={{width: 120, marginRight: 10}} placeholder="请选择状态" value={status}
                        onChange={(e) => setStatus(e)}>
                    {
                        statusData.map((item, index) => {
                            return (
                                <Option value={item.id} key={item.id}>{item.name}</Option>
                            )
                        })
                    }
                </Select>
                <Input placeholder="请输入姓名" style={{width: 200, marginRight: 10}} value={name} onPressEnter={clickSearch}
                       onChange={(e) => setName(e.target.value)}></Input>
                <Input placeholder="请输入手机号" style={{width: 200, marginRight: 10}} value={phone}
                       onPressEnter={clickSearch} onChange={(e) => setPhone(e.target.value)}></Input>
                <Button type="primary" style={{marginRight: 10}} onClick={clickSearch}><span className='iconfont icon-sousuo buttonIcon'></span>搜索</Button>
                <Button type="primary" style={{marginRight: 10}} onClick={clickReset} className='buttonReset'><span style={{fontSize: 13}} className='iconfont icon-zhongzhi buttonIcon'></span>重置</Button>
            </div>
            <div className="bottom">
                <DeTable columns={tableColunm} total={tableTotal}
                         onPaginationChange={(pageNo, pageSize) => getVisitorList('', pageNo, pageSize)}
                         dataSource={tableData}/>
            </div>
            <Modal
                title={auditType === 'audit' ? '人员审批' : '人员详情'}
                visible={auditVisible}
                onCancel={cancelEdit}
                maskClosable={false}
                destroyOnClose={true}
                centered={true}
                width={'500px'}
                className='editClass'
                footer={
                    auditType === 'audit' ?
                        <div style={{width: '100%', textAlign: 'right', padding: '10px 0'}}>
                            <Button className='ratify' loading={confirmLoading} onClick={confirm.bind(this, '1')}>通过</Button>
                            <Button className='noRatify' loading={confirmLoading} onClick={confirm.bind(this, '2')}>不通过</Button>
                        </div> : ''
                }
            >
                <div>
                    <div style={{display: 'flex'}}>
                        <img src={imageUrl} width='116' height='116' style={{borderRadius: 12}}/>
                        <div style={{marginLeft: 24}}>
                            <div>姓&emsp;&emsp;名：<span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>{auditData.name}</span></div>
                            <div style={marginTop10}>手 &nbsp;机 &nbsp;号：<span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>{auditData.phone}</span></div>
                            {/*<div style={marginTop10}>证 &nbsp;件 &nbsp;号：<span style={{color: 'rgba(0, 0, 0, 0.85)'}}>{auditData.credNo}</span></div>*/}
                            <div style={marginTop10}>登记人员：<span
                                style={{color: 'rgba(0, 0, 0, 0.85)'}}>{auditData.registrant}</span></div>
                        </div>
                    </div>
                    <div style={CutOff}></div>
                    <div style={marginTop20}>
                        <div style={titleP}>访问猪场</div>
                        <div style={{display: 'flex', marginTop: 10}}>
                            {
                                pigFarmList.length === 0 ? <div></div> :
                                    pigFarmList.map(item => {
                                        return (
                                            <div style={pigFarmDiv}>{item.label}</div>
                                        )
                                    })
                            }
                        </div>
                    </div>
                    <div style={CutOff}></div>
                    <div style={marginTop20}>
                        <div style={titleP}>授权访问时间</div>
                        <DatePicker style={{width: '45%', marginTop: 10}} value={auditData.time}
                                    disabled></DatePicker>
                    </div>
                    <div style={CutOff}></div>
                    <div style={marginTop20}>
                        <div style={titleP}>审批说明
                            {
                                auditType === 'audit' ?
                                <span style={{color: '#A6410E', fontSize: 10}}> <InfoCircleOutlined/> 驳回时审批说明是必填的</span>:''
                            }
                        </div>
                        <TextArea placeholder='请输入驳回原因' value={personComments} disabled={auditType === 'audit' ?false:true}
                                  onChange={(e) => setPersonComments(e.target.value)}
                                  style={{marginTop: 10, height: 85}}/>
                    </div>
                </div>
                {/*<Form style={{display: 'inline-block'}}>*/}
                {/*    <Form.Item>*/}
                {/*        {getFieldDecorator('id')(*/}
                {/*            <span></span>*/}
                {/*        )}*/}
                {/*    </Form.Item>*/}
                {/*    <Col span={12}>*/}
                {/*        <Form.Item {...formItemLayout} label="人脸图片：">*/}
                {/*            {*/}
                {/*                imageUrl ?*/}
                {/*                    <div><img src={imageUrl} alt="" className="avatar"/></div> :*/}
                {/*                    ''*/}
                {/*            }*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*    <Col span={12}>*/}
                {/*        <Form.Item {...formItemLayout} label="访客姓名：">*/}
                {/*            <span>{auditData.name}</span>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*    /!*<Col span={12}>*!/*/}
                {/*    /!*    <Form.Item {...formItemLayout} label="证件号：">*!/*/}
                {/*    /!*            <span>{auditData.credNo}</span>*!/*/}
                {/*    /!*    </Form.Item>*!/*/}
                {/*    /!*</Col>*!/*/}
                {/*    <Col span={12}>*/}
                {/*        <Form.Item {...formItemLayout} label="手机号：">*/}
                {/*                <span>{auditData.phone}</span>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*    <Col span={12}>*/}
                {/*        <Form.Item {...formItemLayout} label="岗位：">*/}
                {/*            {getFieldDecorator('post')(*/}
                {/*                <Select disabled>*/}
                {/*                    {*/}
                {/*                        userPostData.map((item, index) => {*/}
                {/*                            return (*/}
                {/*                                <Option value={item.dictValue}*/}
                {/*                                        key={item.dictValue}>{item.dictDesc}</Option>*/}
                {/*                            )*/}
                {/*                        })*/}
                {/*                    }*/}
                {/*                </Select>*/}
                {/*            )}*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*    <Col span={12}>*/}
                {/*        <Form.Item {...formItemLayout} label="授权访问时间：">*/}
                {/*            {getFieldDecorator('authVisit')(*/}
                {/*                <DatePicker disabled style={{width: '100%'}}></DatePicker>*/}
                {/*            )}*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*    <Col span={24}>*/}
                {/*        <Form.Item {...formItemLayout100} label="所属猪场：">*/}
                {/*            {getFieldDecorator('pigFramId')(*/}
                {/*                <TreeSelect*/}
                {/*                    disabled*/}
                {/*                    style={{width: '100%'}}*/}
                {/*                    treeData={pigFarmList}*/}
                {/*                    dropdownStyle={{height: 300, overflow: 'auto'}}*/}
                {/*                    placeholder="请选择"*/}
                {/*                    treeCheckStrictly*/}
                {/*                    allowClear*/}
                {/*                    treeCheckable*/}
                {/*                    multiple*/}
                {/*                >*/}
                {/*                </TreeSelect>*/}
                {/*            )}*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*    {*/}
                {/*        auditVisible?*/}
                {/*            <Col span={24}>*/}
                {/*                <Form.Item {...formItemLayout100} label="审批意见：" required={true}>*/}
                {/*                    {getFieldDecorator('comments', {*/}
                {/*                        rules: [{required: true, message: '请输入审批意见！'}, {*/}
                {/*                            pattern: /^[^\s]*$/,*/}
                {/*                            message: '禁止输入空格！'*/}
                {/*                        }],*/}
                {/*                    })(*/}
                {/*                        <TextArea disabled={isDisabled}/>*/}
                {/*                    )}*/}
                {/*                </Form.Item>*/}
                {/*            </Col>:''*/}
                {/*    }*/}
                {/*</Form>*/}
            </Modal>
            <Modal
                title='新增访客'
                visible={editVisible}
                onCancel={cancelEdit}
                maskClosable={false}
                destroyOnClose={true}
                centered={true}
                width={'50%'}
                className='editClass'
                footer={
                    <div style={{width: '100%', textAlign: 'center'}}>
                        <Button type="primary" style={{width: 200}} loading={confirmLoading}
                                onClick={editConfire}>提交</Button>
                    </div>
                }
            >
                <Form style={{display: 'inline-block'}}>
                    <Form.Item>
                        <span></span>
                    </Form.Item>
                    <Col span={12}>
                        <Form.Item {...formItemLayout} label="访客姓名：">
                            {getFieldDecorator('name', {
                                rules: [{required: true, message: '请输入访客姓名！'}, {
                                    pattern: /^[^\s]*$/,
                                    message: '禁止输入空格！'
                                }],
                            })(
                                <Input
                                    placeholder="请输入访客姓名"
                                />,
                            )}
                        </Form.Item>
                    </Col>
                    {/*<Col span={12}>*/}
                    {/*    <Form.Item {...formItemLayout} label="证件号：">*/}
                    {/*        {getFieldDecorator('credNo', {*/}
                    {/*            rules: [{*/}
                    {/*                required: true,*/}
                    {/*                message: '请输入证件号！',*/}
                    {/*                pattern: new RegExp(/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/, "g")*/}
                    {/*            }],*/}
                    {/*        })(*/}
                    {/*            <Input*/}
                    {/*                placeholder="请输入证件号"*/}
                    {/*            />,*/}
                    {/*        )}*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                    <Col span={12}>
                        <Form.Item {...formItemLayout} label="手机号：">
                            {getFieldDecorator('phone', {
                                rules: [{
                                    required: true,
                                    message: '请输入手机号！',
                                    pattern: new RegExp(/^1[3456789]\d{9}$/, "g")
                                }],
                            })(
                                <Input
                                    placeholder="请输入手机号"
                                />,
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item {...formItemLayout} label="岗位：">
                            {getFieldDecorator('post', {
                                rules: [{required: true, message: '请输入岗位！'}],
                            })(
                                <Select>
                                    {
                                        userPostData.map((item, index) => {
                                            return (
                                                <Option value={item.dictValue}
                                                        key={item.dictValue}>{item.dictDesc}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item {...formItemLayout} label="所属猪场：">
                            {getFieldDecorator('pigFramId', {
                                rules: [{required: true, message: '请选择！'}],
                            })(
                                <TreeSelect
                                    style={{width: '100%'}}
                                    treeData={addPigFarmList}
                                    dropdownStyle={{height: 300, overflow: 'auto'}}
                                    placeholder="请选择"
                                    treeCheckStrictly
                                    allowClear
                                    treeCheckable
                                    multiple
                                >
                                </TreeSelect>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item {...formItemLayout} label="授权访问时间：">
                            {getFieldDecorator('authVisit', {
                                rules: [{required: true, message: '请选择授权访问时间！'}],
                            })(
                                <DatePicker style={{width: '100%'}}></DatePicker>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item {...formItemLayout} label="人脸图片：" required={true}>
                            <Upload
                                className="avatar-uploader"
                                name="file"
                                showUploadList={false}
                                action={baseURL+"system/File/upload"}//http://localhost:9081/client/file/upload
                                method="post"
                                headers={headers}
                                beforeUpload={beforeUpload}
                                onChange={handleChange.bind(this)}
                            >
                                {
                                    imageUrl ?
                                        <div><img src={imageUrl} alt="" className="avatar"/><Icon
                                            style={{position: ' absolute', top: '120px'}} type="plus"
                                            className="avatar-uploader-trigger"/></div> :
                                        <Icon type="plus" className="avatar-uploader-trigger"/>
                                }
                            </Upload>
                        </Form.Item>
                    </Col>
                </Form>
            </Modal>
        </Container>

    )
}

// export default Form.create()(Visitor)
const mapState = (state) => ({
    baseURL: state.baseURL,
})

const mapDispatch = (dispatch) => ({
})

export default connect(mapState, mapDispatch)(Form.create()(Visitor))
