import React, {useEffect, useState} from 'react'
import {Col, Form, Modal} from "antd";
import { Container } from './style'
import DeTable from "../../../../component/DeTable";
import moment from "moment";
import AlertRequest from '../../../../request/alert-request'
import { VxTimeFormat,stringFormat } from '../../../../utils/homeFormat'
const _request = new AlertRequest()

const marginTop10 = {marginTop: 10}
const colorBlack = {color: 'rgba(0, 0, 0, 0.85)'}
function Alert(props){

    const { clickDetails } = props

    const [tableData, setTableData] = useState([]) //表格数据
    const [details, setDetails] = useState({})
    const [imageUrl, setImageUrl] = useState('')
    const [modalType, setModalType] = useState(false) //


    const columnsData = [
        {
            title: '日期',
            key: 'createDate',
            dataIndex: 'createDate',
            render: (text, record, index) => (
                <span>{VxTimeFormat(text,'home')}</span>
            )
        },
        // {
        //     title: '类型',
        //     dataIndex: 'warnType',
        //     key: 'warnType',
        //     render: text => (
        //         <span>{text === '0'?'人员':(text === '1'?'车辆':'物资')}</span>
        //     )
        // },
        {
            title: '洗消点名称',
            key: 'washSpotName',
            dataIndex: 'washSpotName',
            // render: (text,record,index) => (
            //     stringFormat(text)
            // )
        },
        {
            title: '异常记录',
            key: 'allCount',
            dataIndex: 'allCount',
        },
        // {
        //     title: '事件',
        //     key: 'eventName',
        //     dataIndex: 'eventName',
        // },
    ]
    useEffect(()=>{
        getVideoMonitorList()
    },[])

    //获取列表
    const getVideoMonitorList = () => {
        let params = {
            // current: 1,
            // size: 7,
        }
        _request.getHomeWarnRecord(params).then(res => {
            setTableData(res)
        })
    }
    const clickRow = (e) => {
        clickDetails('151&警报中心',e.washSpotId,e.createDate)
        // setDetails(e)
        // setImageUrl(e.imgPath)
        // setModalType(true)
    }

    const cancelEdit = () => {
        setModalType(false)
        setImageUrl('')
    }

    return(
        <Container>
            {
                tableData.length === 0?<div style={{width: '100%',textAlign: 'center',lineHeight: '300px'}}>暂无数据</div>:
                    <DeTable columns={columnsData} clickRow={clickRow} dataSource={tableData} isPagination={true}/>
            }
            <Modal
                title='警报信息'
                visible={modalType}
                onCancel={cancelEdit}
                maskClosable={false}
                destroyOnClose={true}
                centered={true}
                width={'520px'}
                className='editClass'
                footer={
                    ''
                }
            >
                <div style={{display: 'flex'}}>
                    <img src={imageUrl} width='174' height='174' style={{borderRadius: 12}}/>
                    <div style={{marginLeft: 24,width: 274}}>
                        <div>类 &emsp;&emsp;型：<span style={colorBlack}>{details.warnType === '0'?'人员':(details.warnType==='1'?'车辆':'物资')}</span></div>
                        <div style={marginTop10}>姓 &emsp;&emsp;名：<span style={colorBlack}>{details.keyWord}</span></div>
                        <div style={marginTop10}>事 &emsp;&emsp;件：<span style={colorBlack}>{details.eventName}</span></div>
                        <div style={marginTop10}>视频位置：<span style={colorBlack}>{details.videoName}</span></div>
                        <div style={marginTop10}>时 &emsp;&emsp;间：<span style={colorBlack}>{details.createdTime}</span></div>
                        {/*<div style={marginTop10}>洗消点：<span style={colorBlack}>{details.washSpotName}</span></div>*/}
                        <p style={{marginTop: 10,float: 'left'}}>洗 &nbsp;消 &nbsp;点：</p><p style={{marginTop: 10,color: 'rgba(0, 0, 0, 0.85)'}}>{details.washSpotName}</p>
                    </div>
                </div>
            </Modal>
        </Container>
    )
}

export default Form.create()(Alert)