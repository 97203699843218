import request from '../https'

class StoreSettingHttp {
    //--------------------------------------------------------员工管理
    //获取闸机列表
    getGateList(params) {
        return request({
            url: '/safety/gate/list',
            method: 'post',
            params
        })
    }
	//获取当前登录用户下的猪场集合
	getPigfarmList(params){
		return request({
		    url: '/safety/area/getChildrenRegionInfo',
		    method: 'post',
		    params
		})
	}
	
    //新增闸机
    addGateInfo(params) {
        return request({
            url: '/safety/gate/add',
            method: 'post',
            params
        })
    }
    //编辑闸机
    editGateInfo(params) {
        return request({
            url: '/safety/gate/update',
            method: 'post',
            params
        })
    }
    //删除闸机
    deleteGateInfo(params) {
        return request({
            url: '/safety/gate/delete',
            method: 'post',
            params
        })
    }
	
	//获取开闸记录
	gateRecordList(params) {
	    return request({
	        url: '/safety/gateRecord/list',
	        method: 'post',
	        params
	    })
	}

    //查询视频列表
    findVideoMonitor(data) {
        return request({
            url: 'safety/videoMonitor/list',
            method: 'post',
            data
        })
    }
    //新增视频
    addVideoMonitor(params) {
        return request({
            url: '/safety/videoMonitor/add',
            method: 'post',
            params
        })
    }
    //获取视频位置
    getVideoList(params) {
        return request({
            url: '/safety/dict/videoList',
            method: 'post',
            params
        })
    }

    //删除视频
    deleteVideoMonitor(params) {
        return request({
            url: '/safety/videoMonitor/delete',
            method: 'post',
            params
        })
    }
    //编辑视频
    editVideoMonitor(params) {
        return request({
            url: '/safety/videoMonitor/update',
            method: 'post',
            params
        })
    }
    
     //获取摄像头关联的猪场栋舍数组
    getVideoFramList(params) {
        return request({
            url: '/safety/videoMonitor/getVideoFramList',
            method: 'post',
            params
        })
    }
}

export default StoreSettingHttp