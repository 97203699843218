import styled from 'styled-components'

//固定的高宽
const headerHeight = 50 //头部高
const asideHeight = `200px` //侧边导航栏宽
const footerHeight = `42px` //底部高

//颜色值
const mainColor = '#5A8BFF' //主色调

export const Container = styled.div`
  height: 100%;
  width: 100%;
`

export const Header = styled.div`
  height: ${headerHeight}px;
    //background: ${mainColor};
  //color: white;
  background: white;
  color: black;
  border-bottom: 1px solid #E0E0E0;

  .home-icon-container {
    width: 193px;
    height: 100%;
    float: left;
    position: relative;

    .home-icon {
      width: 60%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .header-right {
    width: calc(100% - ${asideHeight});
    height: 100%;
    float: right;

    .header-title {
      text-align: center;
      font-size: 26px;
      line-height: 26px;
      font-weight: bold;
      padding-top: 19px;
      padding-right: 200px;
    }

    .header-welcome {
      font-size: 14px;
      line-height: 49px;
      margin-right: 30px;
      text-align: right;
      color: #2A2A37;
      padding-left: 10px;
      display: flex;
      justify-content: space-between;
      &-time{
        display: flex;
        //span{
        //  margin-right: 5px;
        //}
      }
      span {
        //margin: 0 10px;

        &:last-child {
          //cursor: pointer;

          //&:hover {
          //  font-weight: bold;
          //}
        }
      }
    }
  }
`

export const Aside = styled.div`
  width: ${asideHeight};
  height: calc(100% - ${headerHeight}px);
  float: left;
  border-right: 1px solid rgb(232, 232, 232);
  overflow-y: scroll;
`

export const Shade = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  z-index: 1;
`

export const Content = styled.div`
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container{
    height: 50px;
  }

  .ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab.ant-tabs-tab{
    background-color: #E2EAF4;
    margin-right: 10px!important;
    margin-left: 10px;
    height: 30px;
    margin-top: 10px;
    //width: 136px;
    text-align: center;
    color: #2F7ECF;
    line-height: 30px;
    //border-radius: 4px;
    //box-shadow: 0px 1px 4px 0px #C2D3E6;
    border-radius: 4px!important;
  }
  .ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab.ant-tabs-tab svg{
    color: #358AFF;
  }
  .ant-tabs.ant-tabs-card>.ant-tabs-bar .ant-tabs-tab-active{
    background-color: #D7E4F2!important;
    font-weight: 600;
  }
  
  width: calc(100% - ${asideHeight});
    //height: calc(100% - ${headerHeight - 27}px);
  height: calc(100% - 26px) !important;
  margin-top: 40px !important;
  float: right;
  transform: translateY(-40px);
  .ant-tabs-tab-prev-icon{
    color: black;
  }
  .ant-tabs-tab-next-icon{
    color: black;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background-color: rgba(0, 0, 0 ,0.1)!important;
  }
  .content-main {
    height: calc(100% - 27px);
    margin-left: -8px!important;
    .ant-tabs-tabpane {
      height: 100%;

      .content-inline {
        height: 100%;
      }
    }
  }

  .footer {
    font-size: 12px;
    padding: 14px 0;
    line-height: 14px;
    box-sizing: border-box;
    text-align: center;
    color: rgba(0, 0, 0, 0.65);
    background: rgb(240, 242, 245);
  }
`
