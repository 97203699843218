import styled from 'styled-components'

export const Container = styled.div`
  width: fit-content;
  display: inline-block;

  .ant-steps-item-icon {
    width: 32px !important;
    height: 32px !important;
  }

  .ant-steps-item-description {
    font-size: 13px !important;
    margin-top: 0 !important;
  }

  .ant-steps-item-content {
    width: 67% !important;
  }

  .title {
    padding: 10px 0;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }

  .stepIcon {
    background: #5A8BFF;
    border-radius: 50%;
    font-size: 16px;
    color: white;
    transition: all .3s ease-in;

    &:hover {
      transition: all .3s ease-in;
      cursor: pointer;
      background: #ff5c57;

      span {
        display: none;
      }

      .close {
        display: inline;
      }
    }

    span {
      display: inline;
    }

    .close {
      display: none;
    }
  }

  .addFlow {
    cursor: pointer;
    transition: all .3s linear;

    &:hover {
      color: #5A8BFF;
      transition: all .3s linear;
    }
  }
`