import React, {useState, useEffect} from 'react'

import { Container } from './style'

import {Table} from 'antd'

function DeTable(props) {
    const [current, setCurrent] = useState(1)
    const [pageSize, setPageSize] = useState(10)

    useEffect(() => {
    }, [])
    const { total, onPaginationChange, isPagination, clickRow } = props

    const handleChange = (no, size) => {
        setCurrent(no)
        setPageSize(size)
        onPaginationChange(no, size)
    }
    const handleSizeChange = (no, size) => {
        setCurrent(no)
        setPageSize(size)
        onPaginationChange(no, size)
    }
    const handleClick = (e) => {
        if (clickRow){
            clickRow(e);
        }
    }
    return (
        <Container>
            {
                !isPagination?
                    <Table pagination={{ size: 'small', current, pageSize, showSizeChanger: true, total, onChange: handleChange, onShowSizeChange: handleSizeChange }} {...props} />
                    :
                    <Table pagination={false} size="small" {...props} onRow={record => {
                        return {
                            onClick: handleClick.bind(this,record), // 点击行
                        };
                    }}/>
            }
        </Container>
    )
}

export default DeTable