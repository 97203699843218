import React from 'react'
import { Provider } from 'react-redux'
import { Globalstyle } from './style.js'
import store from './store'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import {ConfigProvider} from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import 'moment/locale/zh-cn'

import Login from './page/login/index'
import StartUp from './page/startup/index'
import ToHome from './page/toHome/index'
//审核管理
import Audit from './subPage/audit/index'
//警告中心
import AlertCentre from "./subPage/alertCentre";
//洗消管理
import WashAdmin from './subPage/decontaminate/washAdmin/index'
//洗消管理 - 洗消点设置
import WashSet from './subPage/decontaminate/washSet/index'
//洗消管理 - 洗消流程设置
import WorkFlow from './subPage/decontaminate/workFlowPic/index'
//洗消管理 - 洗消中心管理
import WashGroup from './subPage/decontaminate/washGroup/index'
//车俩管理
import CarAdmin from "./subPage/car/carAdmin";
//车辆管理 - 车辆信息
import ResidentCarInfo from './subPage/car/residentCarInfo/index'
//车辆管理 - 临时车辆登记
import TempCarInfo from './subPage/car/tempCarInfo/index'
//车辆管理 - 车辆洗消记录
import DecontaminationRecord from './subPage/car/decontaminationRecord/index'
//物资管理
import MaterialAdmin from "./subPage/material/materialAdmin";
//物资管理 - 物资登记
import Register from './subPage/material/register/index'
//物资管理 - 物资洗消记录
import Decontamination from './subPage/material/decontamination/index'
//物资管理 - 物品库存
import Stock from './subPage/material/stock/index'
//物资管理 - 物品出入库
import OutAndWarehousing from './subPage/material/outAndWarehousing/index'
//人脸门禁
import PersonGuard from "./subPage/personManage/personGuard";
//人管管理 - 员工管理
import Staff from './subPage/personManage/staffTreeAll/index'
//人管管理 - 临时访客登记
import Visitor from './subPage/personManage/visitorTreeAll/index'
//人管管理 - 人员洗消记录
import PeopleWash from './subPage/personManage/peopleWash/index'

//洗消记录
import washRecord from "./subPage/washRecord";

//洗消统计
import WashTotal from "./subPage/washTotal";

//设备设置
import DeviceSet from "./subPage/device/deviceSet";

//设备管理-智能电表
import ElectricMeter from './subPage/device/electricMeter/index'

//设备管理-智能花洒
import Shower from "./subPage/device/shower/index";

//设备管理-闸机
import Gate from './subPage/device/gate/index'

//设备管理-智能水表
import Water from './subPage/device/water/index'

//设备管理-视频监控设备
import VideoMonitor from './subPage/device/videoMonitor/index'

//视频监控
import VideoMonitoring from './subPage/videoMonitor/index'

//系统用户
import SysUser from './subPage/system/user/index'

//分享沟通
import ShareCommunication from "./subPage/share";


function App() {
  return (
    <React.Fragment>
      <Globalstyle/>
      <Provider store={store}>
        <ConfigProvider locale={zhCN}>
          <HashRouter>
            <Switch>
              <Route path='/' exact render={() => (<Redirect to="/toHome"></Redirect>)}></Route>
              <Route path='/login' component={Login}></Route>
              <Route path='/toHome' component={ToHome}></Route>
              <Route path='/home' component={StartUp}></Route>
              <Route path='/decontaminate/WashSet' component={WashSet}></Route>
              <Route path='/AlertCentre' component={AlertCentre}></Route>
              <Route path='/WashTotal' component={WashTotal}></Route>
              <Route path='/ShareCommunication' component={ShareCommunication}></Route>
              <Route path='/decontaminate/WashAdmin' component={WashAdmin}></Route>
              <Route path='/decontaminate/Audit' component={Audit}></Route>
              <Route path='/decontaminate/WorkFlow' component={WorkFlow}></Route>
              <Route path='/decontaminate/WashGroup' component={WashGroup}></Route>
              <Route path='/car/ResidentCarInfo' component={ResidentCarInfo}></Route>
              <Route path='/car/CarAdmin' component={CarAdmin}></Route>
              <Route path='/car/TempCarInfo' component={TempCarInfo}></Route>
              <Route path='/car/DecontaminationRecord' component={DecontaminationRecord}></Route>
              <Route path='/material/Register' component={Register}></Route>
              <Route path='/material/MaterialAdmin' component={MaterialAdmin}></Route>
              <Route path='/material/Decontamination' component={Decontamination}></Route>
              <Route path='/material/Stock' component={Stock}></Route>
              <Route path='/material/OutAndWarehousing' component={OutAndWarehousing}></Route>
              <Route path='/personManage/PersonGuard' component={PersonGuard}></Route>
              <Route path='/personManage/Staff' component={Staff}></Route>
              <Route path='/personManage/Visitor' component={Visitor}></Route>
              <Route path='/personManage/PeopleWash' component={PeopleWash}></Route>
              <Route path='/personManage/washRecord' component={washRecord}></Route>
              <Route path='/equipment/VideoMonitor' component={VideoMonitor}></Route>
              <Route path='/equipment/VideoMonitoring' component={VideoMonitoring}></Route>
              <Route path='/equipment/Water' component={Water}></Route>
              <Route path='/equipment/ElectricMeter' component={ElectricMeter}></Route>
              <Route path='/equipment/Shower' component={Shower}></Route>
              <Route path='/equipment/DeviceSet' component={DeviceSet}></Route>
              <Route path='/equipment/Gate' component={Gate}></Route>
              <Route path='/user/SysUser' component={SysUser}></Route>
            </Switch>
          </HashRouter>
        </ConfigProvider>
      </Provider>
    </React.Fragment>
  )
}

export default App
