import React, {useState, useEffect} from 'react'
import {Container} from './style'
import {Button, Col, Form, Input, message, Modal, Popconfirm, Select} from "antd";
import StoreSettingHttp from '../../../request/demo'
import CommonRequest from "../../../request/common-request";
import DeTable from "../../../component/DeTable";
import moment from "moment";

const _request = new StoreSettingHttp()
const _common = new CommonRequest()
const {TextArea} = Input;
const {Option} = Select;

function GoodsRoom(props) {
    const {getFieldValue, validateFields, getFieldDecorator, setFieldsValue} = props.form
    const [typeName, setTypeName] = useState('')
    const [washSpotId, setWashSpotId] = useState()
    const [washList, setWashList] = useState([])
    const [tableTotal, setTableTotal] = useState(0)
    const [tableData, setTableData] = useState([])
    const [modalType, setModalType] = useState('add')
    const [editVisible, setEditVisible] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 6},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 16},
        },
    }
    const tableColunm = [
        {
            title: '序号',
            key: 'number',
            render: (text, record, index) => (
                <span>{index + 1}</span>
            )
        },
        {
            title: '洗消点名称',
            dataIndex: 'washSpotName',
            key: 'washSpotName',
        },
        {
            title: '消毒间名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record, index) => (
                <span>
                    <span style={{marginRight: 8}} className='tableA'
                          onClick={openModal.bind(this, 'edit', record)}>编辑</span>
                    <Popconfirm title="确认删除？" okText="删除" okType="danger" cancelText="取消"
                                onConfirm={deleteRow.bind(this, record.id)}>
                        <span className='tableADelete'>删除</span>
                    </Popconfirm>
                </span>
            ),
        },
    ]

    useEffect(() => {
        getGoodsRoomList()
        washSpotList()
    }, [])

    const deleteRow = (id) => {
        _request.delGoodsRoom({id: id}).then(res => {
            message.info('删除成功！')
            getGoodsRoomList()
        })
    }

    const getGoodsRoomList = (ButtonType, pageNo = 1, pageSize = 10) => {
        let param = {
            name: typeName,
            washSpotId,
            pageNo,
            pageSize
        }
        if (ButtonType === 'reset') {
            param = {
                pageNo: 1,
                pageSize: 10,
                name: '',
                washSpotId: ''
            }
        }
        _request.getGoodsRoomList(param).then(res => {
            setTableTotal(res.total)
            setTableData(res.list)
        }).catch(err => {
            console.log(err);
        })
    }

    const washSpotList = () => {
        let params = {}
        _common.getWashSpotList(params).then(res => {
            setWashList(res)
        })
    }

    const openModal = (type, record) => {
        setModalType(type)
        setEditVisible(true)
        if (type !== 'add') {
            setTimeout(() => {
                setFieldsValue({
                    name: record.name,
                    washSpotId: record.washSpotId,
                    id: record.id
                })
            }, 0)
        }
    }
    const clickSearch = () => {
        getGoodsRoomList()
    }
    const clickReset = () => {
        setTypeName('')
        setWashSpotId()
        getGoodsRoomList('reset')
    }
    const editConFire = () => {
        validateFields((err, value) => {
            if (!err) {
                setConfirmLoading(true)
                let washName = ''
                washList.forEach(item => {
                    if(getFieldValue('washSpotId') === item.id) {
                        washName = item.name
                    }
                })
                if (modalType === 'add') {
                    let params = {
                        name: getFieldValue('name'),
                        washSpotId: getFieldValue('washSpotId'),
                        washSpotName: washName,
                    }
                    _request.addGoodsRoom(params).then(res => {
                        setConfirmLoading(false)
                        message.info('新增成功！')
                        setEditVisible(false)
                        getGoodsRoomList()
                    }).catch(err => {
                        setConfirmLoading(false)
                    })
                } else {
                    let params = {
                        name: getFieldValue('name'),
                        washSpotId: getFieldValue('washSpotId'),
                        washSpotName: washName,
                        id: getFieldValue('id'),
                    }
                    _request.updateGoodsRoom(params).then(res => {
                        setConfirmLoading(false)
                        message.info('修改成功！')
                        setEditVisible(false)
                        getGoodsRoomList()
                    }).catch(err => {
                        setConfirmLoading(false)
                    })
                }
            }
        })
    }
    //编辑页取消
    const cancelEdit = () => {
        setEditVisible(false)
        setModalType('')
        setConfirmLoading(false)
    }
    return (
        <Container>
            <div className="top">
                <Button type="primary" style={{marginRight: 10,backgroundColor: '#689F38',borderColor: '#689F38'}} onClick={openModal.bind(this, 'add')}><span className='iconfont icon-tianjia buttonIcon'></span>新增</Button>
                <Input placeholder="请输入消毒间名称" style={{width: 200, marginRight: 10}} value={typeName}
                       onPressEnter={clickSearch} onChange={(e) => setTypeName(e.target.value)}></Input>
                <Select placeholder="请选择洗消点" style={{width: 200, marginRight: 10}} showSearch value={washSpotId}
                        onChange={e => setWashSpotId(e)}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                    {
                        washList.map((item, index) => {
                            return <Option key={item.key} value={item.id}>{item.name}</Option>
                        })
                    }
                </Select>
                <Button type="primary" style={{marginRight: 10}} onClick={clickSearch}><span className='iconfont icon-sousuo buttonIcon'></span>查询</Button>
                <Button type="primary" style={{marginRight: 10}} className='buttonReset' onClick={clickReset}><span className='iconfont icon-zhongzhi buttonIcon' style={{fontSize: 14}}></span>重置</Button>
            </div>
            <div className="bottom" style={{marginTop: 10}}>
                <DeTable columns={tableColunm} total={tableTotal}
                         onPaginationChange={(pageNo, pageSize) => getGoodsRoomList('', pageNo, pageSize)}
                         dataSource={tableData}/>
            </div>
            <Modal
                title={modalType === 'add' ? '新增消毒间' : '编辑'}
                visible={editVisible}
                onCancel={cancelEdit}
                maskClosable={false}
                destroyOnClose={true}
                centered={true}
                width={'50%'}
                className='editClass'
                footer={
                    <div style={{width: '100%', textAlign: 'center'}}>
                        <Button type="primary" style={{width: 200}} loading={confirmLoading}
                                onClick={editConFire}>提交</Button>
                    </div>
                }
            >
                {
                    modalType === 'add' ?
                        <Form style={{height: 150}}>
                            <Form.Item>
                                {getFieldDecorator('id')(
                                    <span></span>
                                )}
                            </Form.Item>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="名称：">
                                    {getFieldDecorator('name', {
                                        rules: [{required: true, message: '请输入消毒间名称！'}],
                                    })(
                                        <Input placeholder="请输入消毒间名称"/>,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="洗消点：">
                                    {getFieldDecorator('washSpotId', {
                                        rules: [{required: true, message: '请选中洗消点！'}],
                                    })(
                                        <Select placeholder="请选择洗消点">
                                            {
                                                washList.map((item, index) => {
                                                    return <Option key={item.key} value={item.id}>{item.name}</Option>
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Form>
                        :
                        <Form style={{height: 150}}>
                            <Form.Item>
                                {getFieldDecorator('id')(
                                    <span></span>
                                )}
                            </Form.Item>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="消毒间名称：">
                                    {getFieldDecorator('name', {
                                        rules: [{required: true, message: '请输入消毒间名称！'}],
                                    })(
                                        <Input placeholder="请输入消毒间名称"/>,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="洗消点：">
                                    {getFieldDecorator('washSpotId', {
                                        rules: [{required: true, message: '请选中洗消点！'}],
                                    })(
                                        <Select placeholder="请选择洗消点">
                                            {
                                                washList.map((item, index) => {
                                                    return <Option key={item.key} value={item.id}>{item.name}</Option>
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Form>
                }
            </Modal>
        </Container>
    )
}

export default Form.create()(GoodsRoom)