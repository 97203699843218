import * as actionTypes from './actionTypes'
let isHttps = 'https:' == document.location.protocol ? 'https://' : 'http://'
const defaultState = {
    subScreen: [{
        title: '首页',
        target: -1,
        active: true
    }],
    // subScreen: [{
    //     title: '调试',
    //     id: 32,
    //     active: true
    // }],
    // baseURL: 'http://192.168.200.13:8081/api/',
    // baseURL: 'http://api.hfmygnqszcyh.com/api/',
    // baseURL: isHttps+'api.rnpig.com/api/',
    baseURL: isHttps+'api.hsfxm.cn/api/',
    userInfo: {}, //用户信息
    powerButtonList: [], //可配置的按钮组
    specialSubScreen: {} //从其他页面进入特定的tab页
}
export default (state = defaultState, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_SCREEN:
            return {
                ...state,
                subScreen: action.data
            }
        case actionTypes.CHANGE_USERINFO:
            return {
                ...state,
                userInfo: action.data
            }
        case actionTypes.CHANGE_POWERBUTTON_LIST:
            return {
                ...state,
                powerButtonList: action.data
            }
        case actionTypes.CHANGE_SPECIAL_SCREEN:
            return {
                ...state,
                specialSubScreen: action.data
            }
            default:
                return state
    }
}
