import React, {useState, useEffect} from 'react'
import {Container} from './style'

import {Button, Input, Modal, Col, Form, message, DatePicker, Upload, Select, Icon} from 'antd'

import moment from 'moment'

import AlertRequest from '../../request/alert-request'

import CommonRequest from '../../request/common-request'

import DeTable from '../../component/DeTable';
import {stringFormat} from "../../utils/homeFormat";

const _request = new AlertRequest()
const _common = new CommonRequest()
const { RangePicker } = DatePicker;

const marginTop10 = {marginTop: 10}
const colorBlack = {color: 'rgba(0, 0, 0, 0.85)'}
function AlertCentre(props) {

    const {getFieldValue, setFieldsValue, getFieldDecorator} = props.form
    const {washSpotId,time} = props
    const [modalType, setModalType] = useState(false) //
    const [imageUrl, setImageUrl] = useState('')
    const [details, setDetails] = useState({})
    const [warnType, setWarnType] = useState('')
    const [washList, setWashList] = useState([])

    const tableColunm = [
        {
            title: '序号',
            key: 'number',
            render: (text, record, index) => (
                <span>{index + 1}</span>
            )
        },
        {
            title: '类型',
            dataIndex: 'warnType',
            key: 'warnType',
            render: text => (
                <span>{text === '0'?'人员':(text === '1'?'车辆':'物资')}</span>
            )
        },
        {
            title: '名称',
            key: 'keyWord',
            dataIndex: 'keyWord',
        },
        {
            title: '事件',
            key: 'eventName',
            dataIndex: 'eventName',
        },
        {
            title: '洗消点',
            key: 'washSpotName',
            dataIndex: 'washSpotName',
            render: (text,record,index) => (
                stringFormat(text,9)
            )
        },
        {
            title: '视频位置',
            key: 'videoName',
            dataIndex: 'videoName',
        },
        {
            title: '时间',
            key: 'createdTime',
            dataIndex: 'createdTime',
            render: (text, record, index) => (
                <span>{moment(text).format("YYYY-MM-DD HH:mm:ss")}</span>
            )
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record, index) => (
                <span>
                    <span className='tableA' style={{marginRight: 8}} onClick={openModel.bind(this, record)}>详情</span>
                </span>
            ),
        },
    ]

    //表格结构
    const [tableTotal, setTableTotal] = useState(0)
    const [tableData, setTableData] = useState([]) //表格数据

    useEffect(() => {
        getVideoMonitorList()
        washSpotList()
    }, [washSpotId,time])
    //获取列表
    const getVideoMonitorList = (ButtonType, pageNo = 1, pageSize = 10) => {
        if (washSpotId){
            setFieldsValue({
                washSpotId: washSpotId,
                time: [moment(time),moment(time)]
            })
        }
        let params = {
            current:pageNo,
            size:pageSize,
            warnType,
            washSpotId: getFieldValue('washSpotId'),
            keyWord: getFieldValue('keyWord'),
        }
        if (getFieldValue('time')){
            // params.startTime = moment(getFieldValue('time')[0]).format("YYYY-MM-DD") + ' 00:00:00'
            // params.endTime = moment(getFieldValue('time')[1]).format("YYYY-MM-DD") + ' 23:59:59'
            params.startTime = moment(getFieldValue('time')[0]).format("YYYY-MM-DD")
            params.endTime = moment(getFieldValue('time')[1]).format("YYYY-MM-DD")
        }
        if (ButtonType === 'reset') {
            params = {
                current: 1,
                size: 10,
            }
        }
        _request.getWarnRecord(params).then(res => {
            setTableTotal(res.total || 0)
            setTableData(res.list)
        })
    }

    const washSpotList = () => {
        let params = {}
        _common.getWashSpotList(params).then(res => {
            setWashList(res)
        })
    }

    //打开搜索
    const openModel = (record) => {
        setDetails(record)
        setImageUrl(record.imgPath)
        setModalType(true)
    }

    const cancelEdit = () => {
        setModalType(false)
        setImageUrl('')
    }

    //搜索
    const clickSearch = () => {
        getVideoMonitorList()
    }

    //重置
    const clickReset = () => {
        setWarnType('')
        props.form.resetFields()
        getVideoMonitorList('reset')
    }
    const {Option} = Select;

    return (
        <Container>
            <div className="top" style={{ display: 'flex' }}>
                <Form layout="inline">
                    <Form.Item>
                        <Select placeholder="类型" style={{width: 100}} value={warnType} onChange={(e) => setWarnType(e)}>
                            <Option key='3' value=''>全部类型</Option>
                            <Option key='0' value='0'>人</Option>
                            <Option key='1' value='1'>车</Option>
                            <Option key='2' value='2'>物</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('washSpotId')(
                            <Select placeholder="请选择洗消点" style={{width: 200}} showSearch
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                {
                                    washList.map((item, index) => {
                                        return <Option key={item.key} value={item.id}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('keyWord')(
                            <Input placeholder="姓名/车牌/物资名称" />
                         )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('time')(
                            <RangePicker style={{ width: 260 }}/>
                        )}
                    </Form.Item>
                </Form>
                <Button type="primary" style={{marginRight: 10, marginTop: 3}} onClick={clickSearch}><span className='iconfont icon-sousuo buttonIcon'></span>搜索</Button>
                <Button type="primary" style={{marginTop: 3}} className='buttonReset' onClick={clickReset}><span style={{fontSize: 13}} className='iconfont icon-zhongzhi buttonIcon'></span>重置</Button>
            </div>
            <div style={{marginBottom: 6}}>合计&nbsp;{tableTotal}&nbsp;条</div>
            <div className="bottom">
                <DeTable columns={tableColunm} total={tableTotal}
                         onPaginationChange={(pageNo, pageSize) => getVideoMonitorList('', pageNo, pageSize)}
                         dataSource={tableData}/>
            </div>

            <Modal
                title='警报信息'
                visible={modalType}
                onCancel={cancelEdit}
                maskClosable={false}
                destroyOnClose={true}
                centered={true}
                width={'520px'}
                className='editClass'
                footer={
                    ''
                }
            >
                <div style={{display: 'flex'}}>
                    <img src={imageUrl} width='174' height='174' style={{borderRadius: 12}}/>
                    <div style={{marginLeft: 24,width: 274}}>
                        <div>类 &emsp;&emsp;型：<span style={colorBlack}>{details.warnType === '0'?'人员':(details.warnType==='1'?'车辆':'物资')}</span></div>
                        <div style={marginTop10}>姓 &emsp;&emsp;名：<span style={colorBlack}>{details.keyWord}</span></div>
                        <div style={marginTop10}>事 &emsp;&emsp;件：<span style={colorBlack}>{details.eventName}</span></div>
                        <div style={marginTop10}>视频位置：<span style={colorBlack}>{details.videoName}</span></div>
                        <div style={marginTop10}>时 &emsp;&emsp;间：<span style={colorBlack}>{details.createdTime}</span></div>
                        {/*<div style={marginTop10}>洗消点：<span style={colorBlack}>{details.washSpotName}</span></div>*/}
                        <p style={{marginTop: 10,float: 'left'}}>洗 &nbsp;消 &nbsp;点：</p><p style={{marginTop: 10,color: 'rgba(0, 0, 0, 0.85)'}}>{details.washSpotName}</p>
                    </div>
                </div>
                {/*<Form style={{display: 'inline-block'}}>*/}
                {/*    {*/}
                {/*        imageUrl ?*/}
                {/*            <Col span={12}>*/}
                {/*                <Form.Item {...formItemLayout} colon={false} label=' '>*/}
                {/*                    <img src={imageUrl} style={{ margin: '0 auto' }} className="avatar"/>*/}
                {/*                </Form.Item>*/}
                {/*            </Col>:''*/}
                {/*    }*/}
                {/*    <Col span={12}>*/}
                {/*        <Form.Item {...formItemLayout}  label="类型：">*/}
                {/*            <span>{details.warnType === '0'?'人员':(details.warnType==='1'?'车辆':'物资')}</span>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*    <Col span={12}>*/}
                {/*        <Form.Item {...formItemLayout}  label="姓名：">*/}
                {/*            <span>{details.keyWord}</span>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*    <Col span={12}>*/}
                {/*        <Form.Item {...formItemLayout}  label="事件：">*/}
                {/*            <span>{details.eventName}</span>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*    <Col span={12}>*/}
                {/*        <Form.Item {...formItemLayout}  label="洗消点：">*/}
                {/*            <span>{details.washSpotName}</span>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*    {*/}
                {/*        details.videoName?*/}
                {/*        <Col span={12}>*/}
                {/*            <Form.Item {...formItemLayout} label="视频位置：">*/}
                {/*                <span>{details.videoName}</span>*/}
                {/*            </Form.Item>*/}
                {/*        </Col>:''*/}
                {/*    }*/}
                {/*    /!*<Col span={12}>*!/*/}
                {/*    /!*    <Form.Item {...formItemLayout}  label="岗位：">*!/*/}
                {/*    /!*        <span>{details}</span>*!/*/}
                {/*    /!*    </Form.Item>*!/*/}
                {/*    /!*</Col>*!/*/}
                {/*    {*/}
                {/*        details.videoName?*/}
                {/*            <Col span={24}>*/}
                {/*                <Form.Item {...formItemLayout100}  label="时间：">*/}
                {/*                    <span>{details.createdTime}</span>*/}
                {/*                </Form.Item>*/}
                {/*            </Col>*/}
                {/*            :*/}
                {/*            <Col span={12}>*/}
                {/*                <Form.Item {...formItemLayout}  label="时间：">*/}
                {/*                    <span>{details.createdTime}</span>*/}
                {/*                </Form.Item>*/}
                {/*            </Col>*/}
                {/*    }*/}
                {/*</Form>*/}
            </Modal>
        </Container>

    )
}

export default Form.create()(AlertCentre)