import React, {useState, useEffect} from 'react'
import {Container} from './style'

import {Button, Input, Modal, Table, TreeSelect, Popconfirm, Col, Form, message, Icon, Upload, Select} from 'antd'

import moment from 'moment'

import VideoRequest from '../../request/video-request'

import CommonRequest from '../../request/common-request'

import DeTable from '../../component/DeTable';
import DeVideo from '../../component/DeVideo'
import CommonHttp from '../../request/common-request'
import {stringFormat} from "../../utils/homeFormat";

const _request = new VideoRequest()
const _common = new CommonRequest()
const _commonrequest = new CommonHttp()
const { TextArea } = Input

function VideoMonitoring(props) {
    const [modalType, setModalType] = useState('') //新增or编辑
    const [editVisible, setEditVisible] = useState(false) //编辑项
    const [confirmLoading, setConfirmLoading] = useState(false) //编辑模态框加载图标
    const [imageUrl, setImageUrl] = useState('')
    const [washList, setWashList] = useState([])
    const [deviceAddress, setDeviceAddress] = useState([])   //设备位置

    const formItemLayout100 = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 4},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 19},
        },
    }
    const [destoryVideo, setDestoryVideo] = useState(false)
    const tableColunm = [
        {
            title: '序号',
            key: 'number',
            render: (text, record, index) => (
                <span>{index + 1}</span>
            )
        },
        {
            title: '洗消点',
            dataIndex: 'setName',
            key: 'setName',
            render: (text,record,index) => (
                stringFormat(text,9)
            )
        },
        {
            title: '视频位置',
            key: 'location',
            dataIndex: 'location'
        },
        {
            title: '说明',
            key: 'remark',
            dataIndex: 'remark',
        },
        {
            title: '创建人',
            key: 'createdBy',
            dataIndex: 'createdBy',
        },
        {
            title: '创建时间',
            key: 'createdTime',
            dataIndex: 'createdTime',
            render: (text, record, index) => (
                <span>{moment(text).format("YYYY-MM-DD HH:mm:ss")}</span>
            )
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record, index) => (
                <span>
						<span className='tableA' style={{marginRight: 8}}
                              onClick={openModal.bind(this, 'open', record)}>播放</span>
                        <span style={{marginRight: 8}} className='tableA'
                              onClick={openModal.bind(this, 'edit', record)}>修改</span>
						<Popconfirm title="确认删除？" okText="删除" okType="danger" cancelText="取消"
                                    onConfirm={deleteRow.bind(this, record.id)}>
							<span style={{marginRight: 8}} className='tableADelete'>删除</span>
						</Popconfirm>
                    </span>
            ),
        },
    ]

    //表格结构
    const [tableTotal, setTableTotal] = useState(0)
    const [tableData, setTableData] = useState([]) //表格数据
    const [childRegionData, setChildRegionData] = useState([]) //猪场列表
    const [pigFarmList, setPigFarmList] = useState([]) //猪场栋舍列表
    const [expandTreeList, setExpandTreeList] = useState([])
    const [onLineType, setOnLineType] = useState('')

    // 闸机记录表格结构
    const [detailVideoUrl, setDetailVideoUrl] = useState('')

    const deviceType = [
        {value: "1", text: "车辆摄像头"},
        {value: "2", text: "人物摄像头"}
    ] //设备类型

    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 8},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 14},
        },
    }
    const {getFieldDecorator} = props.form
    useEffect(() => {
        getVideoMonitorList()
        getCarDeviceType()
        getChildRegionList()
        getPigfarm()
        washSpotList()
    }, [])
    //获取闸机列表
    const getVideoMonitorList = (ButtonType, pageNo = 1, pageSize = 10) => {
        const {getFieldValue} = props.form
        let params = {
            setId: getFieldValue('setId2'),
            location: getFieldValue('location2'),
            current:pageNo,
            size:pageSize
        }
        if (ButtonType === 'reset') {
            params = {}
        }
        _request.getVideoList(params).then(res => {
            setTableTotal(res.total || 0)
            setTableData(res.list)
        })
    }
    //获取车辆摄像头关联的猪场列表
    const getChildRegionList = () => {
        let params = {}
        _commonrequest.getChildRegionList(params).then(res => {
            setChildRegionData(res)
        })
    }
    //获取人员摄像头关联的猪场栋舍列表
    const getPigfarm = () => {
        let params = {
            regionType: 5,
            enterpriseType: 2
        }
        _commonrequest.getFramBuilddingList(params).then(res => {
            let list = []
            res.forEach(item => {
                let childList = []
                item.buildList.forEach(child => {
                    childList.push({
                        title: child.buildingName,
                        value: child.buildingId,
                        isLeaf: true
                    })
                })
                list.push({
                    title: item.pigFramName,
                    value: item.pigFramId,
                    children: childList,
                    // selectable: false,
                    // disableCheckbox: true
                })
            })
            setPigFarmList(list)
        })
    }

    //删除最外的表格行
    const deleteRow = (id) => {
        _request.deleteVideoMonitor(id).then(res => {
            message.info('删除成功！')
            getVideoMonitorList()
        })
    }
    const washSpotList = () => {
        let params = {}
        _common.getWashSpotList(params).then(res => {
            setWashList(res)
        })
    }
    //打开编辑页
    const openModal = (type, record) => {
        // 获取洗消点集合

        const {setFieldsValue} = props.form
        setModalType(type)
        setEditVisible(true)
        if (type === 'edit') { // 编辑页面
            setOnLineType(record.type)
            setTimeout(() => {
                setFieldsValue({
                    setId: record.setId,
                    remark: record.remark,
                    previewUrl: record.previewUrl,
                    id: record.id,
                    // coverBase64: record.coverBase64,
                    location: record.location
                })
            }, 0)
        } else if (type === 'open') {
            setDestoryVideo(false)
            // 播放
            setDetailVideoUrl(record.previewUrl)
        }
    }

    const {getFieldValue, validateFields, setFieldValue} = props.form

    //编辑页提交
    const editConfire = () => {
        validateFields((err, value) => {
            if (!err) {
                /*setConfirmLoading(true)
                let idList = getFieldValue('pigFramId').map(item => {

                    if (item.value) {
                        return item.value + "&" + item.label
                    } else {
                        return item
                    }

                })*/
                if (modalType === 'add') {
                    let params = {
                        previewUrl: getFieldValue('previewUrl'),
                        remark: getFieldValue('remark') || '',
                        setId: getFieldValue('setId'),
                        location: getFieldValue('location'),
                    }
                    _request.addVideoMonitor(params).then(res => {
                        // setConfirmLoading(false)
                        message.info('新增成功！')
                        setEditVisible(false)
                        getVideoMonitorList()
                    })
                } else {
                    /*let params = {
                        equipmentNo: getFieldValue('equipmentNo'),
                        equipmentPosition: getFieldValue('equipmentPosition'),
                        washSpotId: getFieldValue('washSpotId'),
                        pigFramIds: JSON.stringify(idList),
                        type: getFieldValue('type'),
                        id: getFieldValue('id'),
                        name: getFieldValue('name')
                    }*/
                    let params = {
                        id: getFieldValue('id'),
                        previewUrl: getFieldValue('previewUrl'),
                        remark: getFieldValue('remark'),
                        setId: getFieldValue('setId'),
                        location: getFieldValue('location'),
                    }
                    _request.editVideoMonitor(params).then(res => {
                        message.info('修改成功！')
                        setEditVisible(false)
                        getVideoMonitorList()
                    })
                }
            }
        })
    }
    //编辑页取消
    const cancelEdit = () => {
        if (modalType === 'open') {
            setDestoryVideo(true)
        }
        setEditVisible(false)
        setModalType('')
        setConfirmLoading(false)
    }

    //获取车辆设备位置列表
    const getCarDeviceType = () => {
        let params = {
            dictType: 'CAR_WASH_TYPE',
        }
        _commonrequest.getDictList(params).then(res => {
            setDeviceAddress(res.list)
        })
    }

    //搜索
    const clickSearch = () => {
        getVideoMonitorList()
    }

    //重置
    const clickReset = () => {
        props.form.resetFields()
        getVideoMonitorList('reset')
    }


    const {Option} = Select;

    return (
        <Container>
            <div className="top" style={{ display: 'flex' }}>
                <Button type="primary" style={{marginRight: 10,marginTop: 3,backgroundColor: '#689F38',borderColor: '#689F38'}} onClick={openModal.bind(this, 'add')}><span className='iconfont icon-tianjia buttonIcon'></span>新增</Button>
                <Form layout="inline">
                    <Form.Item>
                        {getFieldDecorator('setId2')(
                            <Select placeholder="请选择洗消点" style={{width: 200, marginRight: 10}} showSearch
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                {
                                    washList.map((item, index) => {
                                        return <Option key={item.key} value={item.id}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('location2')(
                            <Input  placeholder="请输入设备位置" style={{width: 200, marginRight: 10}} />
                            // <Select placeholder="请选择设备位置" style={{width: 200, marginRight: 10}} showSearch
                            //         filterOption={(input, option) =>
                            //             option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            //         }>
                            //     {
                            //         deviceAddress.map((item, index) => {
                            //             return <Option key={item.dictValue} value={item.dictValue}>{item.dictDesc}</Option>
                            //         })
                            //     }
                            // </Select>
                        )}
                    </Form.Item>
                </Form>
                <Button type="primary" style={{marginRight: 10,marginTop: 3}} onClick={clickSearch}><span className='iconfont icon-sousuo buttonIcon'></span>搜索</Button>
                <Button type="primary" style={{marginRight: 10,marginTop: 3}} className='buttonReset' onClick={clickReset}><span style={{fontSize: 13}} className='iconfont icon-zhongzhi buttonIcon'></span>重置</Button>
            </div>
            <div className="bottom">
                <DeTable columns={tableColunm} total={tableTotal}
                         onPaginationChange={(pageNo, pageSize) => getVideoMonitorList('', pageNo, pageSize)}
                         dataSource={tableData}/>
            </div>
            <Modal
                title={modalType === 'add' ? '新增视频' : modalType === 'edit' ? '编辑' : '监控视频'}
                visible={editVisible}
                onCancel={cancelEdit}
                maskClosable={false}
                destroyOnClose={modalType === 'open' ? false : true}
                centered={true}
                width={'50%'}
                className='editClass'
                footer={
                    modalType === 'record' || modalType === 'open' ? null :
                        <div style={{width: '100%', textAlign: 'center'}}>
                            <Button type="primary" style={{width: 200}} onClick={editConfire}>提交</Button>
                        </div>
                }
            >
                {
                    modalType === 'add' ?
                        <Form style={{height: 300}}>
                            <Form.Item>
                                <span></span>
                            </Form.Item>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="所属洗消点：">
                                    {getFieldDecorator('setId', {
                                        rules: [{required: true, message: '请选择洗消点！'}],
                                    })(
                                        <Select placeholder="请选择洗消点">
                                            {
                                                washList.map((item, index) => {
                                                    return <Option key={item.key} value={item.id}>{item.name}</Option>
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="设备位置：">
                                    {getFieldDecorator('location', {
                                        rules: [{required: true, message: '请输入设备位置！'}],
                                    })(
                                        <Input />
                                        // <Select placeholder="请选择设备位置">
                                        //     {
                                        //         deviceAddress.map((item, index) => {
                                        //             return <Option key={item.dictValue}
                                        //                            value={item.dictValue}>{item.dictDesc}</Option>
                                        //         })
                                        //     }
                                        // </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item {...formItemLayout100} label="视频地址：">
                                    {getFieldDecorator('previewUrl', {
                                        rules: [{required: true, message: '请输入视频地址！'}],
                                    })(
                                        <TextArea />,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item {...formItemLayout100} label="说明：">
                                    {getFieldDecorator('remark')(
                                        <TextArea />,
                                    )}
                                </Form.Item>
                            </Col>
                        </Form>
                        : modalType === 'edit' ?
                        <Form style={{height: 300}}>
                            <Form.Item>
                                {getFieldDecorator('id')(
                                    <span></span>
                                )}
                            </Form.Item>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="所属洗消点：">
                                    {getFieldDecorator('setId', {
                                        rules: [{required: true, message: '请选择洗消点！'}],
                                    })(
                                        <Select placeholder="请选择洗消点">
                                            {
                                                washList.map((item, index) => {
                                                    return <Option key={item.key} value={item.id}>{item.name}</Option>
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="设备位置：">
                                    {getFieldDecorator('location', {
                                        rules: [{required: true, message: '请输入设备位置！'}],
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item {...formItemLayout100} label="视频地址：">
                                    {getFieldDecorator('previewUrl', {
                                        rules: [{required: true, message: '请输入视频地址！'}],
                                    })(
                                        <TextArea />,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item {...formItemLayout100} label="说明：">
                                    {getFieldDecorator('remark')(
                                        <TextArea />,
                                    )}
                                </Form.Item>
                            </Col>
                        </Form>
                        :<DeVideo destory={destoryVideo} style={{width: '100%'}}
                                 url={detailVideoUrl}></DeVideo>
                    // <Input style={{ width: 600, marginRight: 10 }} value={detailVideoUrl}></Input>
                }
            </Modal>
        </Container>

    )
}

export default Form.create()(VideoMonitoring)